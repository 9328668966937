import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import { SvgIconComponent } from '@mui/icons-material'; // Import type for MUI icons

interface StyledButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  bgColor?: string; // Background color
  hoverBgColor?: string; // Hover background color
  textColor?: string; // Text color
  borderRadius?: string; // Border radius
  buttonWidth?: string; // Width
  buttonHeight?: string; // Height
  Icon?: SvgIconComponent; // Dynamic Icon Component
  startIcon?: React.ReactNode; // Custom start icon
  endIcon?: React.ReactNode; // Custom end icon
  children?: React.ReactNode; // Button label or children
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'hoverBgColor' && prop !== 'textColor' && prop !== 'borderRadius' && prop !== 'buttonWidth' && prop !== 'buttonHeight' && prop !== 'Icon',
})<StyledButtonProps>(({
  bgColor = 'rgba(var(--main-colors-cyan-light-1-rgb),0.9)', // Default color
  hoverBgColor = 'rgba(var(--main-colors-cyan-light-1-rgb),0.75)', // Default hover color
  textColor = 'black', // Default text color
  borderRadius = '27px', // Default radius
  buttonWidth = '156px', // Default width
  buttonHeight = '46px' // Default height
}) => ({
  width: buttonWidth,
  height: buttonHeight,
  padding: '8px 16px',
  borderRadius: borderRadius,
  backgroundColor: bgColor,
  color: textColor,
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: hoverBgColor,
  },
  '& .MuiButton-startIcon': {
    marginRight: '8px',
  },
}));

export default StyledButton;
