import React from 'react';

const CustomVideoIconClose: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
    <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.7666 19.4319L2.41724 0L1.00001 1.4112L1.6222 2.03604C0.701194 2.21395 0 3.02907 0 4V11V16C0 17.1 0.9 18 2 18H11H17.5183L20.3493 20.8431L21.7666 19.4319ZM15.5268 16H11H2L2 11L2 4H3.57781L8 8.44108V14L11.3268 11.7821L15.5268 16ZM20 9V15.6492L21.5742 17.2301C21.8407 16.8902 22 16.4628 22 16V9V4C22 2.96875 21.209 2.11328 20.2037 2.01038C20.1367 2.00352 20.0688 2 20 2H11H6.40883L8.40033 4H20V9Z"
            fill="white" />
    </svg>
);

export default CustomVideoIconClose;
