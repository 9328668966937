import config from '../config.json';
import { SongProps } from 'types/types';

interface DownloadAudioParams {
  operation: 'audio-only';
  song: SongProps;
}

//TODO:pass audio title/the complete song object to the service
const downloadAudioService = async ({ song , operation }: DownloadAudioParams): Promise<string> => {
  const requestBody: any = {
    operation,
    audioS3Key: song.audioPath,//extractS3PathFromUrl(audioUrl),
    title: song.title,
    bucketName: config.mediaBucket
  };
 
  console.log('Request body:', requestBody.audioS3Key, requestBody.bucketName);

  try {
    const response = await fetch(config.downloadAudioServiceUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) throw new Error('Network response was not ok.');

    const data = await response.json();
    if (!data.downloadLink) throw new Error('Download link not found in the response.');
    return data.downloadLink;
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error; // Rethrow to handle it in the component
  }
 
};

export default downloadAudioService;
