
import WebSocketService from './WebSocketService';
import axios from 'axios';
import { startDownload, updateProgress, downloadSuccess, downloadFailure, resetDownload } from 'redux/slices/'; // Adjust import paths as necessary
import { downloadVideoServiceParams } from '../types';
import config from '../config.json';

interface PostParams {
    operation: string;
    audioS3Key: string;
    videoS3Key: string;
    audioStartTime: number;
    bucketName: string;
    connectionId: string;
    title: string;
}

// Helper function to call the Lambda function for combining video and audio
const callDownloadVideoLambda = async (params: downloadVideoServiceParams, connectionId: string): Promise<any> => {
    const postParams: PostParams = {
        operation: params.operation,
        audioS3Key: params.audioPath, 
        bucketName: config.mediaBucket, 
        videoS3Key: params.rawUploadedVideoS3Key,
        audioStartTime: params.audioStartTime,
        connectionId: connectionId,
        title: params.title
    };

    const response = await axios.post(config.downloadVideoServiceUrl, postParams);
    //Note: response only confirms that the lambda function was called successfully
    //for completion listen to the websocket connection see WebSocketService.listenForMessages
    // console.log('Video Download service called successfully:', response.data);
    return response.data;
};

// The main service function using the WebSocketService for real-time notifications
export const downloadVideoService = async (params: downloadVideoServiceParams, dispatch: React.Dispatch<any>): Promise<void> => {
    dispatch(resetDownload());
    dispatch(startDownload()); // set downloading state to true 

    const clientTimestamp = Date.now().toString();
    const clientId = params.clientId;
    const openWebSocketConnectionUrl = `${config.webSocketUrl}?clientId=${clientId}&t=${clientTimestamp}`;

    try {
        await WebSocketService.openConnection(openWebSocketConnectionUrl);
        console.log('WebSocket connection opened.');

        WebSocketService.listenForMessages(
            (message) => {
                console.log(message);
                if (message.progress && typeof message.progress === 'number') {
                    dispatch(updateProgress(message.progress));
                    console.log(`Download progress: ${(message.progress)}%`);
                    
                }else if(message.download_link && typeof message.download_link === 'string'){
                    dispatch(downloadSuccess(message.download_link));
                    console.log(`Download link: ${message.download_link}`);
                } 
            },
            (errorMessage) => {
                dispatch(downloadFailure(errorMessage));
                console.error(errorMessage);
            }
        );

        const connectionId = await WebSocketService.fetchConnectionIdHTTP(clientId, clientTimestamp);
        await callDownloadVideoLambda(params, connectionId);
        //console.log('Video Download operation initiated, waiting for download link...');
    } catch (error) {
        console.error('Error in combineAndDownloadService:', error);
        dispatch(downloadFailure('Failed to process the download request.'));
    }
};
