// Header.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { logout, clearFavorites } from '../../redux/slices';
import { persistor } from '../../redux/store';
import { getUserProfile } from '../../redux/selectors';  
import { Grid, Avatar, styled } from '@mui/material';
import truncate from 'lodash/truncate';
import { Link } from 'react-router-dom';
import UploadIcon from '@mui/icons-material/Upload';
import UploadButton from './UploadButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MainNavigation from './MainNavigation';
import ProfileMenu from './ProfileMenu';
import { setDialogVisibility } from '../../redux/slices/dialogSlice';

const LogoLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:visited, &:hover, &:active {
    color: inherit;
  }
`;

const AccountAvatar = styled(Avatar)`
  background-color: rgba(26, 26, 26);
  cursor: pointer;
  margin-right: 20px;
`;

const IconWrapper = styled(Avatar)`
  background-color: rgba(26, 26, 26);
  cursor: pointer;
`;


const ButtonsContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  gap: 40px;
`;

const UploadButtonContainer = styled(Grid)`
  display: flex;  
  width: 100%;  
  @media (max-width: 746px) {
    display: none; 
  }
`;

const RightItem = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%; 
  gap: 20px;
`;

//TODO: add collapse menu and switch to module.css
const Header: React.FC<{ isFixed?: boolean }> = ({ isFixed = false }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isSearchPath = location.pathname.includes('/search/library');
  const profile = useSelector(getUserProfile);
  const { name: username, email } = profile || { name: '', email: '' };
  const menuRef = React.useRef<HTMLDivElement>(null);
  const handleLogout = () => {
    localStorage.removeItem('favoriteTracksDetails');
    dispatch(clearFavorites());
    dispatch(logout());
    persistor.purge(); // Clears the persisted user state
  };
 

  const handleMenuToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent the click from propagating
    setMenuOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  let timeOutHide: NodeJS.Timeout;
  const handleMouseEnter = () => {
    if (timeOutHide) clearTimeout(timeOutHide);
    setMenuOpen(true);
  };

  const handleMouseLeave = () => {
    timeOutHide = setTimeout(() => {
      setMenuOpen(false);
    }, 1000);
  };

  const handleOpenUploadDialog = () => {
    dispatch(setDialogVisibility({ dialogName: 'uploadDialog', visibility: true }));
  };

  return (
    <Grid container alignItems="center" className="row-padding" sx={{ color: 'white', position: isFixed ? 'fixed' : 'static', zIndex: isFixed ? '900' : '-1', fontFamily: 'Juice', backgroundColor: 'var(--main-colors-black-1)', height: '90px' }}>
      <Grid item xs={3}>
        <LogoLink to="/">MONSTER MUSIC</LogoLink>
      </Grid>
      <ButtonsContainer item xs={6}>
        <MainNavigation />
      </ButtonsContainer>
      <RightItem item xs={1}/>
      <RightItem item xs={2} onMouseLeave={handleMouseLeave}>
        {/* upload button */}
        {isSearchPath && (
          <UploadButtonContainer>
         <UploadButton onClick={handleOpenUploadDialog} />
          </UploadButtonContainer>
        )}
          {/* favorites icon */}
          <IconWrapper>
            <FavoriteIcon
              onClick={() => navigate('/search/favorites')}
              style={{ color: '#9e9e9e' }}
            />
          </IconWrapper>
        
        <div ref={menuRef}>
          <AccountAvatar onMouseEnter={handleMouseEnter} style={{ color: '#9e9e9e' }} onClick={handleMenuToggle}>{username[0]?.toUpperCase()}</AccountAvatar>
          {menuOpen && (
            <ProfileMenu username={username} email={truncate(email, { length: 28 })} onLogout={handleLogout} />
          )}
        </div>
      </RightItem>
    </Grid>
  );
};

export default Header;