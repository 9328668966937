
// This is a basic version of the PlayBarComponent that is used directly to play a song that is passed to it as a prop.
// Important minimap must be set to false when calling the SoundWaveComponent.
// For time being video is disabled.

import React from 'react';
import 'tippy.js/dist/tippy.css';
import '../../../styles/tippy.css';
import truncate from 'lodash/truncate';
import ArtCoverComponent from './ArtCoverComponent';
import SongDetails from './SongDetails';
import TimeValues from './TimeValues';
import FavoriteButton from '../../../components/shared/FavoriteButton';
import VolumeControl from './VolumeControl';
import { SongProps } from 'types/types';
import SoundWaveComponent from '../../../components/media/SoundWaveComponent';
import CustomSwitch from '../../../components/shared/CustomSwitch';
import usePlayBarController from '../hooks/usePlayBarController';
import styles from './PlayBar.module.css';
import Tippy from '@tippyjs/react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DownloadButton from './DownloadButton';
import VideoButton from './VideoButton';
import VideoPlayer from 'components/media/VideoPlayer';


interface PlayBarComponentBasicProps {
  song: SongProps;
}

const SelectTrackMessage = () => (
  <div className={styles.selectTrackMessage}>
    Loading track ...
  </div>
);


const PlayBarComponentBasic: React.FC<PlayBarComponentBasicProps> = ({ song }) =>
{
  const {
    trackReferenceUrl,
    playTrackReference,
    toggleTrackReference,
    // videoOutOfRangeWarning,
    // videoOpen,
    // isFullscreen,
    // showIntroBubble,
    // setShowIntroBubble,
    // videoLink,
    // isVideoAvailable,
    // isPlaying,
    // handleDownload,
    // handleVideo,
    // handlePlayPause,
    // handleFullscreen,
  } = usePlayBarController();

  const handleTrackReady = () => { console.log('track ready') };

  return (
    <div className={styles.container}>
      {/*
      <div className={videoOpen ? styles.fullscreenContainer : styles.container}>
      {song.id && videoLink && videoOpen && (
        <div className={isFullscreen ? styles.fullscreenVideoContainer : styles.videoContainer}>
          <VideoPlayer src={videoLink} videoOpen={videoOpen} />
          {song.id && (
            <div className={styles.fullScreenSongDetailsContainer}>
              <SongDetails title={truncate(song.title, { length: 20 })} artist={song.artist} showIcon={true} />
            </div>
          )}
          <div className={styles.playIcon} onClick={handlePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </div>
          <div className={styles.fullscreenIcon} onClick={handleFullscreen}><FullscreenIcon /></div>
          {videoOutOfRangeWarning && (
            <div className={styles.blackScreen}>
              <div className={styles.blackScreenText}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <WarningAmberIcon style={{ marginRight: '8px' }} />
                  <h2 style={{ margin: 0 }}>Audio Out of Range</h2>
                </div>
                Keep the audio within the video timeline rectangle.
              </div>
            </div>
          )}
        </div>
      )} */}

      <div className={styles.playBarContainer}>
        {song ? (
          <>
            <div className={styles.playBarElements}>
              <div className={styles.toggleReferenceTrackContainer}>
                {trackReferenceUrl && (
                  <CustomSwitch
                    checked={playTrackReference}
                    onToggle={toggleTrackReference}
                    text='Original track'
                    textStyle={{ fontWeight: '700', color: playTrackReference ? 'rgba(255,255,255,0.8)' : 'rgba(255,255,255,0.8)' }}
                  />
                )}
              </div>
              <div className={styles.iconContainer}>

                <ArtCoverComponent id={song.id} hovered={true} coverSleeveUrl={song.coverSleeveUrl} />

              </div>
              <div className={`${styles.songDetailsContainer}`}>
                <SongDetails title={truncate(song.title, { length: 20 })} artist={song.artist} />
              </div>

              <SoundWaveComponent
                id={song.id}
                key={song.id}
                audioUrl={song.audioUrl}
                minimap={false}
                duration={song.duration}
                onTrackReady={handleTrackReady}
                peaksUrl={song.peaksUrl}
              />
 

              <div className={styles.timeValues}>
                <TimeValues bpm={song.bpm} duration={song.duration} id={song.id} />
              </div>
              <VolumeControl />
              <div className={styles.favoritesButtonContainer}>
                <FavoriteButton songProps={song} />
              </div>
              {/* {isVideoAvailable && (
                <Tippy
                  content="Toggle Video"
                  arrow={true}
                  placement='top'
                  visible={showIntroBubble}
                  offset={[0, 4]}
                  theme='customTippyTheme'
                  onClickOutside={() => setShowIntroBubble(false)}
                >
                  <VideoButton onVideo={handleVideo} isOpen={!videoOpen} />
                </Tippy>
              )} */}
              {/* <DownloadButton onDownload={handleDownload} /> */}
            </div>
          </>
        ) : (
          <SelectTrackMessage />
        )}
      </div>
    </div>
  );
};

export default PlayBarComponentBasic;
