import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: '720px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(var(--main-colors-black-2-rgb),1)',
          borderRadius: '10px',
          padding: '10px',
          color: 'rgba(var(--main-colors-white-rgb), 0.9)',
          border: '1px solid rgba(var(--main-colors-black-4-rgb),0)',
          position: 'relative',
          overflow: 'hidden',
        },
      }}
    >
      <Button
        onClick={onClose}
        sx={{
          color: 'rgba(var(--main-colors-white-rgb),1)',
          position: 'absolute',
          top: 8,
          right: 0,
        }}
      >
        <CloseIcon />
      </Button>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          padding: '20px 0',
        }}
      >
        <DialogContentText
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: 'rgba(var(--main-colors-white-rgb), 0.8)',
            fontSize: '20px',
          }}
        >
          <WarningAmberIcon sx={{ color: 'white', marginRight: '18px' }} />
          Are you sure you want to delete?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingBottom: '10px' }}>
        <Button onClick={onClose} sx={{ color: 'rgba(var(--main-colors-white-rgb), 0.9)' }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} sx={{ color: 'white' }} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
