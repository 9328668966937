// src/data/musicData.ts

export const musicalGenres: string[] = [
  "ambient",
  "classical",
  "downtempo",
  "electronic",
  "folk",
  "funk",
  "hip hop",
  "house",
  "indie",
  "jazz",
  "pop",
  "r&b",
  "rock",
  "soul"
];

export const musicalMoods: string[] = [
  "angry",
  "dark",
  "dramatic",
  "energetic",
  "exciting",
  "happy",
  "majestic",
  "negative",
  "neutral",
  "positive",
  "powerful",
  "quirky",
  "relaxed",
  "romantic",
  "sad",
  "tense"
];

export const musicalInstruments: string[] = [
  "acoustic guitar",
  "percussion",
  "bass guitar",
  "brass",
  "electric guitar",
  "instrumental",
  "piano",
  "strings",
  "synth",
  "vocal"
];
