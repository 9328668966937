import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';

interface UploadButtonProps {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  }

// Todo: replace the following with the StyleButton component
const StyledUploadButton = styled(Button)({
  width: '156px',           
  height: '46px',           
  padding: '8px 16px',         
  borderRadius: '27px',     
  backgroundColor: 'rgba(var(--main-colors-cyan-light-1-rgb),0.9)',  
  color: 'black',  
  textTransform: 'none', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: 'rgba(var(--main-colors-cyan-light-1-rgb),0.75)', 
  },
  '& .MuiButton-startIcon': {
    marginRight: '8px',         
  }
});

const UploadButton: React.FC<UploadButtonProps> = ({ onClick }) => (
  <StyledUploadButton
    startIcon={<UploadIcon />}
    onClick={onClick}       // Icon to the left
  >
    Upload
  </StyledUploadButton>
);

export default UploadButton;
