import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DialogState {
  uploadDialog: boolean;
  otherDialog: boolean;
  deeplink: boolean; 
}

export const dialogSlice = createSlice({
  name: 'dialogs',
  initialState: {
    uploadDialog: false,
    otherDialog: false,
    deeplink: false,
  } as DialogState,
  reducers: {
    setDialogVisibility: (state, action: PayloadAction<{ dialogName: keyof DialogState, visibility: boolean }>) => {
      const { dialogName, visibility } = action.payload;
      state[dialogName] = visibility;
      // console.log('dialogName:', dialogName, 'visibility:', visibility);
    },
    setDeeplinkState: (state, action: PayloadAction<boolean>) => {
      state.deeplink = action.payload;
      // console.log('deeplink state set to:', action.payload);
    },
    resetDialogs: (state) => {
      // Reset all dialogs and deeplink state to false
      state.uploadDialog = false;
      state.otherDialog = false;
      state.deeplink = false;
      // console.log('All dialog states have been reset to false');
    }
  }
});

export const { setDialogVisibility, setDeeplinkState, resetDialogs } = dialogSlice.actions;

export default dialogSlice.reducer;
