export const dataSortedByDate: (data: any[]) => any[] = (data: any[]) => {
    const dataSortedByDate = data.sort((a: any, b: any) => {
        let dateA = a.dateCreated ? new Date(a.dateCreated) : new Date();
        let dateB = b.dateCreated ? new Date(b.dateCreated) : new Date();
        return dateB.getTime() - dateA.getTime(); // Most recent first
    });
    return dataSortedByDate;
}

export const extractS3PathFromUrl = (url: string): string => {
    const parsedUrl = new URL(url);
    const s3Path: string = parsedUrl.pathname.substring(1);
    return s3Path;
}

export const extractS3PrefixFromUrl = (url: string): string => {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const pathSegments = pathname.substring(1).split('/');
    pathSegments.pop();
    const s3Prefix = pathSegments.join('/');
    return s3Prefix;
}

export const extractBucketNameFromUrl = (url: string): string => {
    const parsedUrl = new URL(url);
    const bucketName: string = parsedUrl.hostname.split('.')[0];
    return bucketName;
}

export const sanitizeTrackName = (trackName:string) => {
    return trackName
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
  }
