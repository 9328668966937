// TagList.tsx
import React from 'react';
import { capitalize } from 'lodash';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomCloseIcon from '../../../components/shared/CustomCloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { setGenresFilter, setMoodsFilter, setInstrumentsFilter, resetFilters } from 'redux/slices';


const TagsFilter: React.FC = () => {

  const dispatch = useDispatch();
  const { genres, moods, instruments } = useSelector((state: RootState) => state.filters);

  const allTags = [
    ...genres?.map((genre) => ({ type: 'genre', label: genre })) ?? [],
    ...moods?.map((mood) => ({ type: 'mood', label: mood })) ?? [],
    ...instruments?.map((instrument) => ({ type: 'instrument', label: instrument })) ?? [],
  ];

  const handleDelete = (tagToDelete: { type: string, label: string }) => {
    switch (tagToDelete.type) {
      case 'genre':
        dispatch(setGenresFilter(genres?.filter((genre) => genre !== tagToDelete.label) ?? []));
        break;
      case 'mood':
        dispatch(setMoodsFilter(moods?.filter((mood) => mood !== tagToDelete.label) ?? []));
        break;
      case 'instrument':
        dispatch(setInstrumentsFilter(instruments?.filter((instrument) => instrument !== tagToDelete.label) ?? []));
        break;
      default:
        break;
    }
  };

  const handleClearAll = () => {
    dispatch(resetFilters());
  };


  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        mt: allTags.length === 0 ? 0 : 2, 
        mb: allTags.length === 0 ? 0: 2
      }}
    >
      {allTags.map((tag) => (
        <Chip
          key={tag.label}
          label={capitalize(tag.label)}
          onDelete={() => handleDelete(tag)}
          deleteIcon={
            <CloseIcon />
          }
          sx={{
            backgroundColor: 'var(--main-colors-cyan-light-2)',
            color: 'var(--main-colors-black-1)',
            fontSize: '0.75rem',
            height: '20px',
            padding: '0 8px',
            '& .MuiChip-label': {
              padding: '0 8px',
            },
            '& .MuiChip-deleteIcon': {
              fontSize: '0.9rem',
              color: 'var(--main-colors-black-1)',
            },
          }}
        />
      ))}


      {allTags.length > 1 && (
        <Button
          onClick={handleClearAll}
          sx={{
            textTransform: 'none',
            fontSize: '0.75rem',
            padding: '0',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          <Typography sx={{ paddingLeft: '15px', color: 'white', fontSize: '12px' }}>Clear all filters</Typography>
          <CustomCloseIcon style={{ width: '12px', height: '12px', marginLeft: '4px', color: 'white' }} />
        </Button>
      )}

    </Stack>
  );
};

export default TagsFilter;
