// src/services/KeydownManager.ts
import { manageMediaPlayback } from 'redux/thunks/manageMediaPlaybackThunks';
import store from '../redux/store';

class KeydownManager {
  private static instance: KeydownManager;
  private selectedSongId: string | null = null;
  private playPauseState: boolean | undefined = undefined;
  private isSpacebarDisabled: boolean = false;

  private constructor() {}

  static getInstance() {
    if (!KeydownManager.instance) {
      KeydownManager.instance = new KeydownManager();
      KeydownManager.instance.init();
    }
    return KeydownManager.instance;
  }

  private init() {
    document.addEventListener('keydown', this.handleKeyDown);
    // Subscribe to store updates to keep track of selectedSongId and playPauseState
    store.subscribe(() => {
      const state = store.getState();
      this.selectedSongId = state.songList.selectedSongId;
      if (this.selectedSongId) {
        this.playPauseState = state.audio.waveSurferInstances[this.selectedSongId]?.playPauseState;
      } else {
        this.playPauseState = undefined;
      }
    });
  }

  private handleKeyDown = (e: KeyboardEvent) => {
    if (this.isSpacebarDisabled) return;
    if (e.code === 'Space' && this.selectedSongId) {
      e.preventDefault();
      if (this.playPauseState) {
        store.dispatch(manageMediaPlayback(this.selectedSongId, false));
      } else {
        store.dispatch(manageMediaPlayback(this.selectedSongId, true));
      }
    }
  };

  public disableSpacebar() {
    this.isSpacebarDisabled = true;
  }

  public enableSpacebar() {
    this.isSpacebarDisabled = false;
  }

  public cleanUp() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }
}

export default KeydownManager;
