import { Observable, interval, merge, combineLatest, EMPTY } from 'rxjs';
import { withLatestFrom, map, filter, catchError } from 'rxjs/operators';
import { waveSurferManager } from './waveSurferManager'; // Adjust the path as needed

const createVideoTimelineObservable = (selectedSongId: string) => {

  if (!selectedSongId) {
    // console.warn('No song ID provided, skipping subscription setup.');
    return EMPTY; // Return an empty observable if no ID is provided
  }

  const desiredInterval = 50;

  const getInstance = () => waveSurferManager.getInstance(selectedSongId);

  // Observable for 'seeking' events created when a new song is selected
  const seekingObservable = waveSurferManager.getEventObservable(selectedSongId, 'seeking').pipe(
    map(currentTime => ({ source: 'seeking', currentTime })),
    catchError(err => {
      // console.error('Error in seekingObservable:', err);
      return EMPTY; // Handle the error by returning an empty observable
    })
  );

  // Observable for play/pause state
  const playStateObservable = new Observable(subscriber => {
    const waveSurferInstance = getInstance();
    if (!waveSurferInstance) {
      subscriber.complete();
      return;
    }

    const isPlaying = () => waveSurferInstance.isPlaying();
    subscriber.next(isPlaying());

    const onPlay = () => subscriber.next(true);
    const onPause = () => subscriber.next(false);

    waveSurferInstance.on('play', onPlay);
    waveSurferInstance.on('pause', onPause);

    // Cleanup
    return () => {
      waveSurferInstance.un('play', onPlay);
      waveSurferInstance.un('pause', onPause);
    };
  });
  
    // Observable for 'audioprocess' events at an interval, only when playing
    const audioProcessObservable = combineLatest([interval(desiredInterval), playStateObservable]).pipe(
      filter(([, isPlaying]) => !!isPlaying), // pass through only when isPlaying is true
      withLatestFrom(waveSurferManager.getEventObservable(selectedSongId, 'audioprocess')),
      map(([ , currentTime]) => {
        const waveSurferInstance = getInstance();
        if (!waveSurferInstance) {
          throw new Error('WaveSurfer instance unavailable during audioProcess');
        }
        return { source: 'audioProcess', currentTime: waveSurferInstance.getCurrentTime() };
      }),
      catchError(err => {
        // Error in audioProcessObservable
        return EMPTY; // Handle the error gracefully
      })
    );

  const playObservable = waveSurferManager.getEventObservable(selectedSongId, 'play').pipe(
    map(() => {
      const waveSurferInstance = getInstance();
      if (!waveSurferInstance) {
        throw new Error('WaveSurfer instance unavailable at play event');
      }
      const currentTime = waveSurferInstance.getCurrentTime();
      return { source: 'play', currentTime };
    }),
    catchError(err => {
      // console.error('Error in playObservable:', err);
      return EMPTY; // Handle the error gracefully
    })
  );

  // Merge observables
  return merge(seekingObservable, audioProcessObservable, playObservable);
};

export const rxjsAudioService = {
  createVideoTimelineObservable
};
