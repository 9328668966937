import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styles from './IconButton.module.css'; 
import CustomTooltip from '../../../components/shared/TooltipComponent';
interface CloseButtonProps {
  onClose: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClose }) => {
  return (
    <CustomTooltip text="">
    <IconButton aria-label="close"  className={`${styles.iconButton} ${styles.iconButtonHover}`} onClick={onClose}>
      <CloseIcon className={styles.icon}  />
    </IconButton>
    </CustomTooltip >
  );
};

export default CloseButton;
