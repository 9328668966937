import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useAppDispatch } from 'hooks';
import { SoundWavePlaybarContainerProps } from 'src/types';
import { selectSelectedSongDuration } from 'redux/selectors';
import { seekTrackTo } from 'redux/slices';
import VideoTimelineDragger from './VideoTimelineDragger';
import { manageMediaPlayback } from 'redux/thunks/manageMediaPlaybackThunks';
import styles from './SoundWavePlaybarContainer.module.css';
import SoundWaveComponent from './SoundWaveComponent';

    const SoundWavePlaybarContainer: React.FC<SoundWavePlaybarContainerProps> = ({ id }) => {
    const containerWidth = 450;
    const [loading, setLoading] = useState<boolean>(true);
    const [lastSeekPosition, setLastSeekPosition] = useState<number>(0);
    const [waveformWidth, setWaveformWidth] = useState<number>(containerWidth);
    const [videoRectangleWidth, setVideoRectangleWidth] = useState<number>(containerWidth);

    const selectedSongRef = useRef(null);
    const currentMinimapRef = useRef<HTMLDivElement | null>(null);
    const miniMapContainerRef = useRef<HTMLDivElement | null>(null);
    const resizeObserver = useRef<ResizeObserver | null>(null);

    const dispatch = useAppDispatch();
    const videoState = useSelector((state: RootState) => state.video, shallowEqual);
    const isVideoOpen = useSelector((state: RootState) => state.video.videoOpen, shallowEqual);
    const selectedSong = useSelector((state: RootState) => state.songList.selectedSong, shallowEqual);
    const selectedSongDuration = useSelector(selectSelectedSongDuration, shallowEqual);
    const playTrackReference = useSelector((state: RootState) => state.trackReference.playTrackReference);
    const { url: trackReferenceUrl, trackReferenceId} = useSelector((state: RootState) => state.trackReference);
    const calculateVideoRectangleWidth = useCallback((newContainerWidth: number): number => {
        if (!selectedSongDuration) return 0;
        const ratio = Math.round(videoState.videoDuration / selectedSongDuration * 100) / 100;
        return ratio * newContainerWidth;
    }, [selectedSongDuration, videoState.videoDuration]);

    const resizeObserverCallback = useCallback((entries: ResizeObserverEntry[]) => {
        for (let entry of entries) {
            const newWidth = entry.contentRect.width;
            setWaveformWidth(prevWidth => {
                if (prevWidth !== newWidth) {
                    return newWidth;
                }
                return prevWidth;
            });
            setVideoRectangleWidth(calculateVideoRectangleWidth(newWidth));
        }
    }, [calculateVideoRectangleWidth]);

    useEffect(() => {
        if (selectedSong) {
            copyMiniMap(selectedSong);
        }
    }, [selectedSong]);


    useEffect(() => {
        if (!selectedSong?.id) return
        const selectedSongMinimap: HTMLDivElement | null = document.querySelector(`#minimap-container-${selectedSong.id}`);
        if (!selectedSongMinimap) return
        if (playTrackReference) { 
            selectedSongMinimap.style.visibility = 'hidden'; //not required will be hidden in selectedSongRef.current
        } else {
            selectedSongMinimap.style.visibility = 'visible';
        }
    }, [playTrackReference]);

    /* TODO: this was the implementation of 'stop and go to last position' and should
    be re introduced as an option when video editing. See useKeydownHandler Hook and audioSlice.ts (to manage the last position state?)
        useEffect(() => {
            const handleKeyDown = (event: KeyboardEvent) => {
                // console.log('key pressed');
                if (event.key === ' ') {
                    event.preventDefault(); // Prevents the default space bar action (e.g., scrolling)
                    if (videoState.isPlaying) { // on space bar press if is playing seek to last seek position and pause
                        dispatch(seekTrackTo({ instanceId: id, seek: lastSeekPosition }));
                        dispatch(manageMediaPlayback(id, false));
                    } else { //if not playing, play the track from the last seek position
                        dispatch(manageMediaPlayback(id, true));
                    }
                }
            };
    
            window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }, [dispatch, id, videoState.isPlaying, lastSeekPosition]);
    */
    useEffect(() => {
        if (!resizeObserver.current) {
            resizeObserver.current = new ResizeObserver(resizeObserverCallback);
        }
        if (miniMapContainerRef.current) {
            resizeObserver.current.observe(miniMapContainerRef.current);
        }

        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
                resizeObserver.current = null;
            }
        };
    }, [resizeObserverCallback]);

    const handleSeekTrackTo = useCallback((newPositionX: number) => {
        const element = document.getElementById('minimaps-container');
        const positionXWaveform = element?.getBoundingClientRect().left || 0;
        const audioProgressPercent = (newPositionX - positionXWaveform) / waveformWidth;
        setLastSeekPosition(audioProgressPercent);
        if (selectedSong?.id) {
            dispatch(seekTrackTo({ instanceId: id, seek: audioProgressPercent }));
        }
    }, [dispatch, id, selectedSong?.id, waveformWidth]);

    const handleAudioRectangleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        handleSeekTrackTo(e.clientX);
    }, [handleSeekTrackTo]);

    // Copy the minimap from the selected song to the minimap container
    const copyMiniMap = (selectedSong: any) => { //this will be called on all instances of minimap
        selectedSongRef.current = selectedSong;


        if (currentMinimapRef.current) { //set the previous selected minimap to hidden
            currentMinimapRef.current.style.visibility = 'hidden';
        }

        //get a reference to the minimap of the selected song instance
        const selectedSongMinimap: HTMLDivElement | null = document.querySelector(`#minimap-container-${selectedSong.id}`);

        //for track reference it stops here and will show/hide the reference in 
        if (selectedSongMinimap && miniMapContainerRef.current) {
            const styles = {
                height: '40px',
                width: '100%',
                position: 'absolute',
                visibility: 'hidden',
                backgroundColor: 'var(--main-colors-black-2)'
            };

            Object.assign(selectedSongMinimap.style, styles);

            //move the the selected song (hiddden) minimap to the minimaps container in the PlayBar
            //in case it is already there, no changes to the dom will occur
            miniMapContainerRef.current.appendChild(selectedSongMinimap);

            setTimeout(() => {
                selectedSongMinimap.style.visibility = 'visible';
                setLoading(false);
            }, 100);
            selectedSongMinimap.classList.remove('minimap-hidden');
            currentMinimapRef.current = selectedSongMinimap;
        }
    };

    const minimapContainerStyle = useMemo(() => ({
        width: '100%',
    }), []);



    return (

        <div id="audio-editor" style={{ width: `${containerWidth}px`, height: '50px', overflow: 'hidden' }} className={styles.waveformContainer}>
            <div
                id='minimaps-container'
                ref={miniMapContainerRef}
                style={minimapContainerStyle}
                className={`${styles.minimapsContainer} ${loading ? styles.minimapsContainerHidden : ''}`}
            >

                {/* this should render when track reference is loaded. show / hide will change when we play it */}
                <>

                    {trackReferenceUrl  && (
                    <div style={{ display: playTrackReference ? 'block' : 'none' }}>
                        <SoundWaveComponent
                        id={trackReferenceId}
                        audioUrl={trackReferenceUrl}
                        minimap={false}
                    />
                    </div>
                    )}</>

            </div>

            {isVideoOpen && (
                <div id="audio-rectangle" className={styles.audioRectangle} onClick={handleAudioRectangleClick}>
                    <div style={{ width: '100%' }}></div>
                </div>
            )}
            {isVideoOpen && videoRectangleWidth && selectedSongDuration && selectedSong && (
                <VideoTimelineDragger width={videoRectangleWidth} audioDuration={selectedSongDuration} id={selectedSong.id} handleSeekTrackTo={handleSeekTrackTo} />
            )}
        </div>
    );
};

export default SoundWavePlaybarContainer;
