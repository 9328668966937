import { Download } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import styles from './IconButton.module.css'; 
import CustomTooltip from '../../../components/shared/TooltipComponent';
interface DownloadButtonProps {
  onDownload: () => void;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ onDownload }) => {
  return (
    <CustomTooltip text="Download">
    <IconButton aria-label="download"  className={`${styles.iconButton} ${styles.iconButtonHover}`} onClick={onDownload}>
      <Download className={styles.icon}  />
    </IconButton>
    </CustomTooltip >
  );
};

export default DownloadButton;
