// src/hooks/usePlayBarController.ts
import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'src/redux/store';
import { nextPrevSong, ActionTypes } from 'redux/thunks/songListThunks';
import { openVideo, exitVideo, setVideoOffsetInSeconds, playOriginalTrack } from 'redux/slices';
import { manageMediaPlayback } from 'redux/thunks/manageMediaPlaybackThunks';
import { manageOriginalTrackPlaybackThunks } from 'redux/thunks/manageOriginalTrackPlaybackThunks';
import { downloadVideoService } from 'services/downloadVideoService';
import { downloadVideoServiceParams } from 'types';
import { triggerDownload } from 'utils/triggerDownload';
import downloadAudioService from 'services/downloadAudioService';

const usePlayBarController = () => {
  const [videoOutOfRangeWarning, setVideoOutOfRangeWarning] = useState(false);
  const selectedSong = useSelector((state: RootState) => state.songList.selectedSong);
  const dispatch = useDispatch<AppDispatch>();
  const videoOpen = useSelector((state: RootState) => state.video.videoOpen);
  const videoInRange = useSelector((state: RootState) => state.video.videoInRange)
  const [isFullscreen, setFullscreen] = useState(false);
  const [showIntroBubble, setShowIntroBubble] = useState(false);
  const trackReferenceUrl = useSelector((state: RootState) => state.trackReference.url);
  const playTrackReference = useSelector((state: RootState) => state.trackReference.playTrackReference);
  const songList = useSelector((state: RootState) => state.songList?.songs);
  const videoLink = useSelector((state: RootState) => state.upload.videoLink);
  const rawUploadedVideoS3Key = useSelector((state: RootState) => state.upload.rawUploadedVideoS3Key);
  const isVideoAvailable = useSelector((state: RootState) => state.video.videoAvailable);
  const videoOffsetInSeconds = useSelector((state: RootState) => state.video.videoOffsetInSeconds);
  const isPlaying = useSelector((state: RootState) => state.video.isPlaying);
  const clientId = useSelector((state: RootState) => state.user?.profile?.email);
  const currentIndex = songList?.findIndex(song => song.id === selectedSong?.id);
  const { isLoading, downloadLink } = useSelector((state: RootState) => state.download);
  const playingTrackId = useSelector((state: RootState) => state.audio.playingTrackId);
  const isAtStart = currentIndex === 0;
  const isAtEnd = currentIndex === (songList?.length ?? 0) - 1;
  const oneResult = songList?.length === 1;

  useEffect(() => {
    if (downloadLink && !isLoading) {
      setTimeout(() => {
        triggerDownload(downloadLink);
      }, 2000);
    }
  }, [downloadLink, isLoading]);

  useEffect(() => {
    if (isVideoAvailable) {
      dispatch(openVideo());
      setShowIntroBubble(true);
      const timer = setTimeout(() => {
        setShowIntroBubble(false);
      }, 20000);
      return () => clearTimeout(timer);
    }
  }, [isVideoAvailable]);

  useEffect(() => {
    setVideoOutOfRangeWarning(!videoInRange);
  }, [videoInRange]);

  const handleNextSong = useCallback(() => {
    dispatch(nextPrevSong({ actionType: ActionTypes.SELECT_NEXT }));
  }, [dispatch]);

  const handlePreviousSong = useCallback(() => {
    dispatch(nextPrevSong({ actionType: ActionTypes.SELECT_PREVIOUS }));
  }, [dispatch]);

  const handleDownload = () => {
    if (isLoading) {
      return;
    }
    if (isVideoAvailable) {
      handleVideoDownload();
    } else {
      handleAudioDownload();
    }
  };

  const handleVideoDownload = () => {
    if (!rawUploadedVideoS3Key || !clientId) return;
    const title = selectedSong?.title.replace(/ /g, "_")|| 'video';
    const params: downloadVideoServiceParams = {
      audioUrl: selectedSong?.audioUrl || '',
      audioPath: selectedSong?.audioPath || '',
      rawUploadedVideoS3Key,
      audioStartTime: videoOffsetInSeconds || 0,
      operation: 'combine',
      clientId: clientId,
      title: title,
    };
    // console.log('Download video params:', params);
    downloadVideoService(params, dispatch);
  }

  const handleAudioDownload = () => {
    if (!selectedSong) return;
    downloadAudioService({
      song: selectedSong,
      operation: 'audio-only',
    }).then(response => {
      triggerDownload(response);
    })
      .catch(error => {
        console.error('Download failed', error);
      });
  }

  const handleVideo = () => {
    setShowIntroBubble(false);
    if (!videoOpen) {
      dispatch(openVideo());
    } else {
      dispatch(setVideoOffsetInSeconds(0));
      dispatch(exitVideo());
    }
  };

  const handlePlayPause = () => {
    if (!selectedSong) return;
    const id = selectedSong.id;
    if (playingTrackId === `${id}`) {
      dispatch(manageMediaPlayback(id, false))
    } else {
      dispatch(manageMediaPlayback(id, true))
    }
  };

  const toggleTrackReference = () => {
    if (!selectedSong) return;
    dispatch(playOriginalTrack(!playTrackReference));
    const id = selectedSong.id;
    dispatch(manageOriginalTrackPlaybackThunks(id,true));
  };

  const handleFullscreen = useCallback(() => {
    if (videoOpen) {
      setFullscreen(prevState => !prevState);
    }
  }, [videoOpen]);

  return {
    videoOutOfRangeWarning,
    selectedSong,
    videoOpen,
    isFullscreen,
    showIntroBubble,
    setShowIntroBubble,
    trackReferenceUrl,
    playTrackReference,
    videoLink,
    isVideoAvailable,
    isPlaying,
    handleNextSong,
    handlePreviousSong,
    handleDownload,
    handleVideo,
    handlePlayPause,
    toggleTrackReference,
    handleFullscreen,
    isAtStart,
    isAtEnd,
    oneResult
  };
};

export default usePlayBarController;
