import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { FilterParams } from 'src/types';
 
 
export const initialFilterState: FilterParams = {
  genres: [],
  moods: [],
  instruments: [],
  bpmRange: [0, 200],
  durationRange: [0, 300],
  artist: undefined,
  title: undefined,
};

const filterSlice = createSlice({
  name: 'filters',
  initialState: initialFilterState,
  reducers: {
    setGenresFilter(state, action: PayloadAction<string[]>) {
      state.genres = action.payload;
    },
    setMoodsFilter(state, action: PayloadAction<string[]>) {
      state.moods = action.payload;
    },
    setInstrumentsFilter(state, action: PayloadAction<string[]>) {
      state.instruments = action.payload;
    },
    setBpmRangeFilter(state, action: PayloadAction<[number, number]>) {
      state.bpmRange = action.payload;
    },
    setDurationRangeFilter(state, action: PayloadAction<[number, number]>) {
      state.durationRange = action.payload;
    },
    setTitleFilter(state, action: PayloadAction<string | undefined>) {
      console.log('setTitleFilter', action.payload);
      state.title = action.payload;
    },
    setArtistFilter(state, action: PayloadAction<string | undefined>) {
      console.log('setArtistFilter', action.payload);
      state.artist = action.payload;
    },
    resetFilters(state) {
      // Resets each filter to its initial state
      // console.log('resetFilters');
      state.genres = [];
      state.moods = [];
      state.instruments = [];
      state.bpmRange = [0, 200];
      state.durationRange = [0, 300];
      state.title = undefined;
      state.artist = undefined;
    },
   
  },
});

export const selectTotalFilters = createSelector(
  [state => state.filters.genres, state => state.filters.moods, state => state.filters.instruments],
  (genres, moods, instruments) => genres.length + moods.length + instruments.length
);

export const { setGenresFilter, setMoodsFilter, setInstrumentsFilter, setBpmRangeFilter, setDurationRangeFilter, resetFilters,setTitleFilter, setArtistFilter} = filterSlice.actions;
export default filterSlice.reducer;
