import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserProfile } from '../redux/selectors';

// Hotjar Component
const Hotjar = () => {
  const profile = useSelector(getUserProfile);
  const { name: username, email } = profile || { name: '', email: '' };
  const enableHotjar = process.env.REACT_APP_ENABLE_HOTJAR === 'true';
  const [hotjarInitialized, setHotjarInitialized] = useState(false); // Track initialization

  useEffect(() => {
    // Check if Hotjar script is already added
    const isHotjarLoaded = !!document.querySelector('script[src*="hotjar"]');

    if (enableHotjar && !hotjarInitialized && !isHotjarLoaded) {
    
      (window.hj as any) = window.hj || function () { (window.hj!.q = window.hj!.q || []).push(arguments); };

      (function (h: any, o: any, t: string, j: string) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); };
        h._hjSettings = { hjid: 5133069, hjsv: 6 };
        const a = o.getElementsByTagName('head')[0];
        const r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

      // Provide explicit consent for Hotjar
      // Check if Hotjar is defined before giving consent
      // Todo: after internal testing add consent notice for users
      if (window.hj) {
        window.hj('consent', true); // Provide consent to Hotjar tracking
      }


      // Mark Hotjar as initialized
      setHotjarInitialized(true);
    }
  }, [ enableHotjar, hotjarInitialized]);

  useEffect(() => {
    // Identify the user in Hotjar after login
    const identifyUserInHotjar = () => {
      if (window.hj && username && email) {
        window.hj('identify', email, { username, email });
        // Mark user as identified in session storage
        sessionStorage.setItem('hotjar_user_identified', 'true');
      }
    };

    // Only identify if user has not been identified yet
    const hasBeenIdentified = sessionStorage.getItem('hotjar_user_identified') === 'true';
    if (enableHotjar && !hasBeenIdentified && username && email) {
      identifyUserInHotjar();
    }

  }, [enableHotjar, username, email]);

  return null; // No UI needed
};

export default Hotjar;
