//StyledMultipleSelect.tsx

import { styled } from '@mui/material/styles';
import { MenuItem, FormControl, Checkbox, MenuProps as MuiMenuProps, OutlinedInput, InputLabel } from '@mui/material';
 
const PAPER_WIDTH = 360;

export const MenuProps: Partial<MuiMenuProps> = {
  PaperProps: {
    style: {
      width: PAPER_WIDTH,
      backgroundColor: 'var(--main-colors-black-3)',
      color: 'white',
      marginTop: '5px',
      overflow: 'hidden', // Ensuring overflow is managed
    },
  },
  MenuListProps: {
    style: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)', // Create two columns
      gap: '5px', // Optional: add a gap between items
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  }
};


export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: "36px",
    padding: "6px 12px",
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: "rgba(var( --main-colors-white-rgb), 0.5)"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "rgba(var( --main-colors-white-rgb), 0.4)"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "rgba(var( --main-colors-white-rgb), 1)"
    },
    '& .MuiSelect-icon': {
      color: 'rgba(var( --main-colors-white-rgb), 0.5)',
    }
  },
  '& .MuiInputLabel-outlined': {
    fontSize: '12px',
    transform: 'translate(14px, 10px) scale(1)',
    color: 'rgba(255, 255, 255, 0.5)',
    '&.Mui-focused': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(1)',
      color: 'white',
      backgroundColor: 'black',
      padding: '0px 2px 0px 2px',
      fontSize: '12px'
    }
  }
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '12px',
  transform: 'translate(14px, 12px) scale(1)',
  color: 'rgba(255, 255, 255, 0.5)',
  '&.Mui-focused': {
    color: 'rgba(255, 255, 255, 0.5)',
  },
}));

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  height: '36px',
  padding: '6px 12px',
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: "rgba(var(--main-colors-white-rgb), 0.5)",
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: "rgba(var(--main-colors-white-rgb), 0.4)",
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: "rgba(var(--main-colors-white-rgb), 1)",
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'var(--main-colors-black-4)',
  },
  // Ensure the menu item fits well in the grid column
  paddingBottom: '4px',
  paddingTop: '4px',
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "var(--main-colors-light-grey)",
  "&.Mui-checked": {
    color: "var(--main-colors-cyan-light-2)"
  }
}));