import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getUserProfile } from '../../../redux/selectors';  
import { Button, Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import SingleSlider from './SingleSlider';
import MultipleSelectCheckmarks from './MultipleSelectCheckmarks';
import DeleteDialog from '../../../components/shared/DeleteDialog';
import { deleteFromTracksTbl, deleteTracksFromMusiio } from '../../../services/trackService';
import TagsFilter from './TagsFilter';
import { useDispatch } from 'react-redux';
import { setGenresFilter, setMoodsFilter, setInstrumentsFilter, setBpmRangeFilter, setDurationRangeFilter } from 'redux/slices';
import SearchFieldComponent from './SearchFieldComponent';
import { musicalGenres, musicalMoods, musicalInstruments } from '../../../data/tagSelectionValues';
import styles from './ActionsBar.module.css';

interface SelectFilterProps {
  names: string[];
  labelName: string;
  onChange: (selectedValues: string[]) => void;
  filterKey: 'genres' | 'moods' | 'instruments';
}

const SelectFilter: React.FC<SelectFilterProps> = ({ names, labelName, onChange, filterKey }) => (
  <div className={styles.selectBox}>
    <MultipleSelectCheckmarks names={names} labelName={labelName} onChange={onChange} filterKey={filterKey} />
  </div>
);

const ActionsBar: React.FC = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getUserProfile);
  const { role, userId } = profile ? profile : { role: '', userId: '' };
  const songListBin = useSelector((state: RootState) => state.songList.bin);
  const [open, setOpen] = useState(false);
  const isAdmin = role === 'admin' && userId === '264f23bc-d751-466f-a249-863b808baaf5';

  const handleGenreChange = useCallback((selectedGenres: string[]) => {
    dispatch(setGenresFilter(selectedGenres));
  }, [dispatch]);

  const handleMoodChange = useCallback((selectedMoods: string[]) => {
    dispatch(setMoodsFilter(selectedMoods));
  }, [dispatch]);

  const handleInstrumentsChange = useCallback((selectedInstruments: string[]) => {
    dispatch(setInstrumentsFilter(selectedInstruments));
  }, [dispatch]);

  const handleBPMRangeChange = useCallback((bpmRange: number[]) => {
    if (bpmRange.length === 2) {
      dispatch(setBpmRangeFilter(bpmRange as [number, number]));
    } else {
      console.error("Invalid BPM range:", bpmRange);
    }
  }, [dispatch]);

  const handleDurationRangeChange = useCallback((durationRange: number[]) => {
    if (durationRange.length === 2) {
      dispatch(setDurationRangeFilter(durationRange as [number, number]));
    } else {
      console.error("Invalid duration range:", durationRange);
    }
  }, [dispatch]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    setOpen(false);
    console.log('Will attempt to delete: ',songListBin);
    if( !songListBin || songListBin.length === 0) return;
    const deleted = await deleteFromTracksTbl(songListBin);
    console.log('DB delete executed: ',deleted);
    const deleteFromMusiio = await deleteTracksFromMusiio(songListBin);
    console.log('Musiio delete executed: ',deleteFromMusiio);
  };

  return (
    <div className={styles.container}>
      {/* Row 1 actions container */}
      <div className={styles.actionsContainer}>
        {/* Filters action*/}
        <div className={styles.filters}>
          <SelectFilter names={musicalGenres} labelName='Select genres' onChange={handleGenreChange} filterKey='genres' />
          <SelectFilter names={musicalMoods} labelName='Select moods' onChange={handleMoodChange} filterKey='moods' />
          <SelectFilter names={musicalInstruments} labelName='Select instruments' onChange={handleInstrumentsChange} filterKey='instruments' />
          <div className={`${styles.sliderBox} ${styles.selectBox}`}>
            <SingleSlider initialValue={300} min={0} max={5 * 60} steps={10} labelName='Select duration' sliderType='time' onChange={handleDurationRangeChange} />
          </div>
          <div className={`${styles.sliderBox} ${styles.selectBox}`}>
            <SingleSlider initialValue={100} min={0} max={200} steps={10} labelName='Select BPM' sliderType='number' onChange={handleBPMRangeChange} />
          </div>
        </div>

        {/* Search action */}
        <div className={`${styles.searchField} ${styles.selectBox}`}>
          <SearchFieldComponent
            label="Enter Keywords"
            fullWidth
            InputProps={{
              style: { color: 'white' },
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{ color: 'white' }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {/* Admin actions */}
        <div className={styles.adminActions}>
          {isAdmin && (
            <Box sx={{
              display: 'flex', justifyContent: 'flex-end', marginRight: '10px'
            }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#272727',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#ff0000',
                    color: 'white',
                  },
                }}
                onClick={handleClickOpen}
              >
                Delete Track
              </Button>
            </Box>
          )}
        </div>
      </div>
      {/* Row 2 Tags */}
      <div className={styles.tagsContainer}>
        <TagsFilter />
      </div>
      <DeleteDialog open={open} onClose={handleClose} onConfirm={handleConfirmDelete} />
    </div>
  );
};

export default ActionsBar;
