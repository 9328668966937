import React from 'react';
import styles from './LandingPage.module.css';
import fileIcon from 'assets/images/fileIcon.svg';

interface UploadAreaProps {
  getInputProps?: () => any;
}

const UploadArea: React.FC<UploadAreaProps> = () => (
  <div className={styles.uploadAreaContainer}>
    <img className={styles.uploadIcon} loading="lazy" src={fileIcon} alt="file icon" />
    <div className={styles.uploadActionsContainer}>
      <span className={styles.actionText}>Click to upload reference</span>
    </div>
    <div className={styles.fileTypesInfo}>We accept MP3, MP4 and MOV (max. 160MB)</div>
  </div>
);

export default UploadArea;
