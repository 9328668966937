import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/redux/store';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import IconButton from '@mui/material/IconButton';
import { findSimilarTracks } from 'redux/slices/trackReferenceSlice';
import { debounce } from 'lodash';
import { SongProps } from 'types/types';
import CustomTooltip from '../../../components/shared/TooltipComponent';
import styles from './IconButton.module.css'; 
 
interface SimilarityButtonProps {
    song: SongProps;
}

const SimilarityButton: React.FC<SimilarityButtonProps> = ({ song }) => {
    
    const dispatch = useDispatch<AppDispatch>();
    const debouncedHandleClick = debounce(() => {
        // console.log(song)
        dispatch(findSimilarTracks({ song }));
    }, 5000, { leading: true, trailing: false });

    return (
        <CustomTooltip text="Find similar">
            <IconButton aria-label="favorite"    className={`${styles.iconButton} ${styles.iconButtonHover}`}  onClick={debouncedHandleClick}>
            <JoinLeftIcon className={styles.icon} />
            </IconButton>
        </CustomTooltip>
    );
};

export default SimilarityButton;
