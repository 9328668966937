import * as React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styles from './ContinueButton.module.css';
import StyledButton from '../../../components/shared/StyledButton'; // 


interface NextButtonProps {
  handleContinue: () => void
}

const NextButton: React.FC<NextButtonProps> = ({ handleContinue }) => {

  return (
    <div className={styles.container}>
      <StyledButton
        onClick={handleContinue}
        bgColor="rgba(var(--main-colors-cyan-light-1-rgb),0.9)"
        hoverBgColor="rgba(var(--main-colors-cyan-light-1-rgb),0.75)"
        textColor="black"
        borderRadius="7px"
        buttonWidth="150px"
        buttonHeight="50px"
        endIcon={<ArrowForwardIosIcon />}
      >CONTINUE</StyledButton>
    </div>
  );
};

export default NextButton;
