import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { FilterParams } from 'src/types';
import { loadSongs, resetSongList, resetLastEvaluatedKey, setSongsLoading  } from '../slices';
import { manageMediaPlayback } from './manageMediaPlaybackThunks';
import { mapSongs } from '../../utils/songMapping';
import { fetchFilteredSongs } from 'services/filterService'

export const ActionTypes = {
  SELECT_NEXT: 'SELECT_NEXT',
  SELECT_PREVIOUS: 'SELECT_PREVIOUS',
} as const;

type ActionType = typeof ActionTypes[keyof typeof ActionTypes];

interface FetchFilteredPayload {
  lastKey?: Record<string, any>; // Optional lastKey parameter
}


export const fetchFiltered = createAsyncThunk(
  'songList/fetchFiltered',
  async (payload: FetchFilteredPayload | undefined, { getState, dispatch, rejectWithValue }) => {
    try {
     // Get the current state and extract the pageLimit and filters from songList
     const state: RootState = getState() as RootState;

     const pageLimit = state.songList.pageLimit;
     const filters = state.filters;

     // Check if lastKey is passed in the payload
     const lastKey = payload?.lastKey;

     // If lastKey is not provided, reset the song list before fetching
     if (!lastKey) {
       dispatch(resetSongList());
       dispatch(setSongsLoading(true));
     }

       // Fetch the filtered songs using the filters and lastKey if provided
       const result = await fetchFilteredSongs(filters, lastKey, pageLimit);
      //  console.log('fetchFilteredSongs result here');
      return result;
    } catch (error) {
      return rejectWithValue('Failed to fetch songs');
    }
  }
);

export const updateSongStates = (songs: any) => (dispatch: AppDispatch) => {
  dispatch(loadSongs(songs));
};

interface NextPrevSongArgs {
  actionType: ActionType;
  songId?: string;
}

export const nextPrevSong = createAsyncThunk<void, NextPrevSongArgs, { state: RootState }>(
  'songList/nextPrevSong',
  async ({ actionType }, { dispatch, getState }) => {
    // const state = getState();
    const { trackReference, songList } = getState();
    const songs = songList.songs;

    //check if original-track is playing if so retrieve the track id of the selected 
    const currentSongId = (trackReference.playTrackReference) ? trackReference.selectedTrack : songList.selectedSongId;

    let nextSongId: string | undefined;

    if (songs && songs.length > 0) {
      const currentIndex = songs.findIndex(song => song.id === currentSongId);
      let newIndex: number;

      if (actionType === 'SELECT_NEXT') {
        newIndex = (currentIndex + 1) % songs.length;
      } else { // SELECT_PREVIOUS
        newIndex = currentIndex - 1 < 0 ? songs.length - 1 : currentIndex - 1;
      }

      nextSongId = songs[newIndex].id;
    }

    if (nextSongId) {
      dispatch(manageMediaPlayback(nextSongId, true));
    }
  }
);

