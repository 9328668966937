import React from "react";
import Switch from '@mui/material/Switch';

interface CustomSwitchProps {
    checked: boolean;
    onToggle: (checked: boolean) => void;
    text?: string;
    textStyle?: React.CSSProperties;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ checked, onToggle, text, textStyle }) => {

    const combinedTextStyle: React.CSSProperties = {
        color: 'rgba(255, 255, 255, 0.6)',
        letterSpacing: '0.15px',
        alignSelf: 'stretch',
        flexGrow: 1,
        whiteSpace: 'nowrap',
        marginTop: '10px',
        fontSize: '16px',
        fontFamily: 'sans-serif',
        ...textStyle,
    };

    return (
        <>
            <Switch
                checked={checked}
                onChange={(e) => onToggle(e.target.checked)}
                inputProps={{ 'aria-label': 'Switch to original track' }}
                disabled={false}
                sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#00E5FF', // Color of the thumb (button) when checked
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#00E5FF', // Color of the track when checked
                    },
                    '& .MuiSwitch-switchBase': {
                      color: 'rgba(0, 229, 255, 0.3)', // Color of the thumb when unchecked
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: 'rgba(0, 229, 255, 0.4)', // Color of the track when unchecked
                    },
                  }}
                  
            />

            {text && (<div style={combinedTextStyle}>
                {text}
            </div>
            )}
        </>
    );
};

export default CustomSwitch;
