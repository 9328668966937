import React from 'react';

const Playground: React.FC = () => {
  return (
    <h1 style={{color:'white',textAlign:'center'}}>
      Welcome to the Playground
    </h1>
  );
}

export default Playground;
