// uploadSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Action = 'upload' | 'processing' | null;
interface progressPayloadParams { //This should be shared with the slice!
  value: number;
  action: Action;
}
interface UploadState {
  progress: progressPayloadParams;
  isUploading: boolean;
  isUploadComplete: boolean;
  videoLink: string | null;
  audioReferenceUrl: string | null;
  rawUploadedVideoS3Key: string | null;
  file: File | null;
  uploadedFile: { name: string, size: number, type:string}
}

const testVideos = ['https://monstermusic-io-media.s3.eu-west-1.amazonaws.com/assets/countdown-one-minute.mp4','https://monstermusic-io-media.s3.eu-west-1.amazonaws.com/uploads/references/video/YsMyQe6ejoECIzw%3D/720p/1717250061987-input.mp4'];

const initialState: UploadState = {
  progress: {value:0,action:null},
  isUploading: false,
  isUploadComplete: false,
  videoLink: null, 
  //videoLink: testVideos[1], //Todo: add debug flag
  audioReferenceUrl: null,
  rawUploadedVideoS3Key: null,

  file: null,
  uploadedFile: {
    name: '',
    size: 0,
    type:''
  },
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    startUpload: (state) => {
      state.isUploading = true;
      state.isUploadComplete = false;
      state.progress = {value:0,action:null};
      state.videoLink = null;
      state.audioReferenceUrl = null;
    },
    setUploadProgress: (state, action: PayloadAction<progressPayloadParams>) => {
      state.progress = action.payload;
    },
    setUploadedFile: (state, action) => {
      state.uploadedFile = action.payload;
    },
    setUploadComplete: (state, action: PayloadAction<boolean>) => {
      state.isUploadComplete = action.payload;
      if (action.payload) {
        state.isUploading = false;
        state.progress = {value:100,action:'upload'};
      }
    },
    setVideoLink: (state, action: PayloadAction<string>) => {
      state.videoLink = action.payload;
    },
    setAudioReferenceUrl: (state, action: PayloadAction<string>) => {
      state.audioReferenceUrl = action.payload;
    },
    setRawUploadedVideoS3Key: (state, action: PayloadAction<string>) => {
      state.rawUploadedVideoS3Key = action.payload;
    },
    setFile(state, action: PayloadAction<File | null>) {
      state.file = action.payload;
    },
    resetUpload: (state) => {
      state.progress = {value:0,action:null};
      state.isUploading = false;
      state.isUploadComplete = false;
      state.videoLink = null;
      //this is the original uploaded video, assuming it is in better quality - use for download
      state.rawUploadedVideoS3Key = null; 
      state.file = null;
    },
  },
});

export const { startUpload, setUploadProgress, setUploadComplete, setVideoLink, setAudioReferenceUrl, resetUpload, setUploadedFile, setFile, setRawUploadedVideoS3Key } = uploadSlice.actions;

export default uploadSlice.reducer;
