import config from '../config.json';
import { AuthResponse, UserProfile } from 'src/types';

// Ensure that fetchUser returns AuthResponse with tokens and profile
const fetchUser = async (email: string, password: string): Promise<AuthResponse> => {
  const url = config.signInUrl;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    // Extract profile information from the response
    const profile: UserProfile = {
      email: data.profile.email,
      name: data.profile.email.split('@')[0],
      role: data.profile.role || 'user',
      userId: data.profile.userId,
    };

    // Return the full AuthResponse with tokens and profile
    return {
      accessToken: data.accessToken,
      profile,
    };
  } catch (error) {
    console.error('Error fetching user:', error);
    throw new Error('Failed to fetch user');
  }
};

export default fetchUser;
