import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from '../components/shared/Header';
import MusicLibraryContainer from '../features/MusicLibrary/containers/MusicLibraryContainer';
import ErrorPage from '../pages/ErrorPage';
import LandingPageContainer from '../features/LandingPage/containers/LandingPageContainer';
import TrackPageContainer from '../features/MusicLibrary/containers/TrackPageContainer';
import TrackPageContainerAdmin from '../features/MusicLibrary/containers/TrackPageContainerAdmin';

function MainLayout() {
    const location = useLocation();
    const isSearchPath = location.pathname.includes('/search');
    const { role, userId } = useSelector((state: RootState) => {
        const profile = state.user.profile;
        return profile ? { role: profile.role, userId: profile.userId } : { role: '', userId: '' };
    });

    //Admin user can access edit all tracks  
    const TrackPage = (role === 'admin' && userId === '264f23bc-d751-466f-a249-863b808baaf5')
        ? TrackPageContainerAdmin
        : TrackPageContainer;

    return (
        <>
            <Grid container direction="column" style={{ overflowY: 'hidden' }}>
                <Grid item>
                    <Header isFixed={isSearchPath} />
                </Grid>
                <Grid item >
                    <Routes>
                        <Route path="/" element={<Navigate to="/home" />} />
                        <Route path="/home" element={<LandingPageContainer />} />

                        {/* Route for basic search */}
                        <Route path="/search/:type?" element={<MusicLibraryContainer />} />

                        {/* New route for dynamic search library */}
                        <Route path="/search/library/:tagType/:value" element={<MusicLibraryContainer />} />

                        <Route path="/track/:trackName/:id" element={<TrackPage />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </Grid>
            </Grid>
        </>
    );
}

export default MainLayout;
