// waveSurferManager
import WaveSurfer, {WaveSurferOptions, WaveSurferEvents} from '@cyborganic/wavemonster.js';

import { Observable } from 'rxjs';

class WaveSurferManager {
    private instances: { [id: string]: WaveSurfer } = {};
    private counter = 1;

    createInstance(id: string, options: WaveSurferOptions): WaveSurfer {
        const waveSurfer = WaveSurfer.create(options);
        this.instances[id] = waveSurfer;
        // this.logInstanceCount();
        return waveSurfer;
    }

    getInstance(id: string): WaveSurfer | undefined {
        return this.instances[id];
    }

    getEventObservable(id: string, eventName: keyof WaveSurferEvents): Observable<any> {
        // console.log('getEventObservable id ',id);
        const waveSurfer = this.instances[id];
        if (!waveSurfer) {
            throw new Error("WaveSurfer instance not found");
        }

        return new Observable(subscriber => {
            const handler:any = (data: any) => subscriber.next(data);
            waveSurfer.on(eventName, handler ) ;

            // Cleanup
            return () => waveSurfer.un(eventName, handler);
        });
    }

    destroyInstance(id: string): void {
        if (this.instances[id]) {
            this.instances[id].destroy();
            delete this.instances[id];
            // this.logInstanceCount();
        }
    }

    getInstanceCount(): number {
        return Object.keys(this.instances).length;
    }

    private logInstanceCount(): void {
        console.log(`WS MANAGER::: ${this.getInstanceCount()} / ${this.counter++} created.`);
    }
}

export const waveSurferManager = new WaveSurferManager();
