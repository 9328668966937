import styled from 'styled-components';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

interface TimeValuesProps {
  bpm: number;
  duration: number;
  id: string;
}


const TextContainer = styled.div`
  text-align: left;
  white-space: pre-wrap;
  font-synthesis: none;
  position: absolute;

`;

const TimeValuesContainer = styled.div`
  width: 120px;
  height: 100%;
  margin: 0;
  display: flex;  
  flex-direction: column;  
  justify-content: center;  
  padding-left: 12px;  
  position: relative;  
`;

const Bpm = styled(TextContainer)`
  color: rgba(158, 158, 158, 1);
  font-weight: 400;
  font-size: 9px;
  letter-spacing: 0.24px;
  left: 20px;
  top: 44px;
`;

const Timer = styled(TextContainer)`
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.28px;
  left: 20px;
  top: 24px;
`;

const TimeValues: React.FC<TimeValuesProps> = ({ bpm, duration, id }) => {

  const waveSurferInstance = useSelector((state: RootState) => state.audio.waveSurferInstances[id]);
  const currentTime = waveSurferInstance?.currentTime || 0;

  // if (!waveSurferInstance) {
  //   return (
  //     <div style={{width:'120px', padding: '0px', alignItems: 'center', display: 'flex' }}>
  //       <Skeleton variant="rectangular" height={50} width={120} animation="wave" sx={{ bgcolor: 'var(--main-colors-black-2)' }} />
  //     </div>
  //   );
  // }

  // Helper function to format time in mm:ss
  function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  const formattedCurrentTime = formatTime(currentTime); // Implement formatTime to convert seconds to mm:ss

  return (
    <TimeValuesContainer>
      <Bpm>{bpm} BPM</Bpm>
      <Timer>{formattedCurrentTime} / {formatTime(duration)}</Timer>
    </TimeValuesContainer>
  );
};

export default TimeValues;
