import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store'; //
import MainLayout from './layouts//MainLayout';
import Playground from './pages/Playground'; // for testing purposes
import SignIn from './features/SignIn/containers/SignInPage';
 

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const App: FC = () => {

    const isAuth = useSelector((state: RootState) => state.user.isAuth);

    return (
        <div>
            <>
                <Router>
                    <Routes>
                        {/* Playground  is for testing */}
                        <Route path="/playground/" element={<Playground />} />
                        <Route
                            path="/login"
                            element={isAuth ? <Navigate to="/" /> : <SignIn />}
                        />
                        <Route
                            path="*"
                            element={
                                isAuth ? (
                                    <MainLayout />
                                ) : (
                                    <Navigate to="/login" />
                                )
                            }
                        />
                    </Routes>
                </Router>
            </>
        </div>
    );
}

export default App;
