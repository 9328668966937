import React from 'react';
import styles from './UploadDialog.module.css';
import fileIcon from 'assets/images/fileIcon.svg';

interface UploadAreaProps {
  getInputProps: () => any;
  inputRef: React.Ref<HTMLInputElement>;
}

const UploadArea: React.FC<UploadAreaProps> = ({ getInputProps, inputRef }) => (
  <div className={styles.uploadAreaContainer}>
    <input {...getInputProps()} ref={inputRef} />
    <img className={styles.uploadIcon} loading="lazy" src={fileIcon} alt="file icon" />
    <div className={styles.uploadActionsContainer}>
      <span className={styles.actionText}>Click to upload</span>
      <span className={styles.dragDropText}>or drag and drop</span>
    </div>
    <div className={styles.fileTypesInfo}>We accept MP3, MP4 and MOV (max. 160MB)</div>
  </div>
);

export default UploadArea;
