import * as React from 'react';
import { styled } from '@mui/system';
import _ from 'lodash';
import {
    Slider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    MenuProps as MuiMenuProps
} from '@mui/material';

interface SingleSliderProps {
    min: number;
    max: number;
    initialValue: number;
    labelName: string;
    sliderType: 'time' | 'number';
    steps: number;
    onChange: (value: number[]) => void;
}

interface Mark {
    value: number;
    label?: string;
}

const PAPER_WIDTH = 180;
const MenuProps: Partial<MuiMenuProps> = {
    PaperProps: {
        style: {
            width: PAPER_WIDTH,
            backgroundColor: 'var(--main-colors-black-3)',
            color: 'white',
            marginTop: '10px',
            padding: '0px 10px 0px 10px'
        },
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    }
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    width: '100%',
    '& .MuiOutlinedInput-root': {
        height: "36px",
        padding: "6px 12px",
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(var( --main-colors-white-rgb), 0.5)"
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(var( --main-colors-white-rgb), 0.4)"
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(var( --main-colors-white-rgb), 1)"
        },
        '& .MuiSelect-icon': {
            color: 'rgba(var( --main-colors-white-rgb), 0.5)',
        }
    },
    '& .MuiInputLabel-outlined': {
        fontSize: '12px',
        transform: 'translate(14px, 10px) scale(1)',
        color: 'rgba(255, 255, 255, 0.5)',
        '&.Mui-focused': {
            color: 'rgba(255, 255, 255, 0.5)',
        },
        '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(1)',
            color: 'white',
            backgroundColor: 'black',
            padding: '0px 2px 0px 2px',
            fontSize: '12px'
        }
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root.Mui-selected, &.MuiMenuItem-root:hover, &.MuiMenuItem-root.Mui-focusVisible, &.MuiMenuItem-root.Mui-focused': {
        backgroundColor: 'var(--main-colors-black-3) !important'
    },
    margin: '-4px 0',
    padding: '0px 10px 0px 10px',
}));

const SingleSlider: React.FC<SingleSliderProps> = ({ min, max, initialValue, steps, labelName, sliderType, onChange }) => {
    const [sliderValue, setSliderValue] = React.useState<number[]>([min, initialValue]);
    // Debounced onChange handler
    const debouncedOnChange = React.useMemo(() => _.debounce(onChange, 1000), [onChange]);
    const [currentLabel, setCurrentLabel] = React.useState<string>(labelName);

    const formatTime = (seconds: number) => {
        const min = Math.floor(seconds / 60);
        const sec = seconds % 60;
        return `${min}:${sec < 10 ? '0' + sec : sec}`;
    };

    const formatValue = (value: number) => sliderType === 'time' ? formatTime(value) : value.toString();

    const generateMarks = (min: number, max: number, step: number): Mark[] => {
        const marks: Mark[] = [];
        for (let value = min; value <= max; value += step) {
            const mark: Mark = {
                value,
                label: (value === min || value === max) ? formatValue(value) : undefined
            };
            marks.push(mark);
        }
        return marks;
    }

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number[]);
        debouncedOnChange(newValue as number[]);
        const newLabel = `${formatValue((newValue as number[])[0])} - ${formatValue((newValue as number[])[1])}`;
        setCurrentLabel(newLabel);
    };

    React.useEffect(() => {
        return () => {
            debouncedOnChange.cancel();
        };
    }, [debouncedOnChange]);

    return (
        <div>
            <StyledFormControl variant="outlined" fullWidth>
                <InputLabel id="slider-label-select" shrink={false}>{currentLabel}</InputLabel>
                <Select
                    labelId="slider-label-select"
                    value={''}
                    displayEmpty
                    MenuProps={MenuProps}
                >
                    <StyledMenuItem disableRipple>
                        <Slider
                            onClick={(e) => e.stopPropagation()}
                            value={sliderValue}
                            onChange={handleSliderChange}
                            min={min}
                            max={max}
                            step={steps}
                            valueLabelDisplay="off"
                            valueLabelFormat={value => formatValue(value)}
                            aria-labelledby="slider-label"
                            marks={generateMarks(min, max, steps)}
                            sx={{
                                color: 'var(--main-colors-cyan-light-2)',
                                height: 3,
                                '&:hover, &:focus': {
                                    backgroundColor: 'transparent'
                                },
                                '& .MuiSlider-thumb': {
                                    width: 14,
                                    height: 14,
                                    backgroundColor: 'var(--main-colors-cyan-light-1)',
                                    '&:hover, &:focus, &:active': {
                                        boxShadow: 'inherit'
                                    }
                                },
                                '& .MuiSlider-track': {
                                    height: 3
                                },
                                '& .MuiSlider-rail': {
                                    height: 3,
                                    backgroundColor: 'rgba(255, 255, 255, 0.3)'
                                },
                                '& .MuiSlider-mark': {
                                    backgroundColor: 'white'
                                },
                                '& .MuiSlider-markLabel': {
                                    color: 'white',
                                    fontSize: '10px',
                                },
                            }}
                        />
                    </StyledMenuItem>
                </Select>
            </StyledFormControl>
        </div>
    );
}

export default SingleSlider;
