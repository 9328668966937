import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Chip, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause'; // Import Pause icon
import DownloadIcon from '@mui/icons-material/Download';
// import FavoriteIcon from '@mui/icons-material/Favorite';

import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';
import { RootState, AppDispatch } from 'src/redux/store';
import PlayBarComponentBasic from '../components/PlayBarComponentBasic';
import FavoriteButton from '../../../components/shared/FavoriteButton';
import styles from './TrackPageContainer.module.css';
import config from '../../../config.js';
import { mapSongs } from '../../../utils/songMapping';
import { getTrackById } from '../../../services/trackService';
import { manageMediaPlayback } from 'redux/thunks/manageMediaPlaybackThunks';
import { SongProps } from 'types/types';
import { setSelectedSongById, resetSongList } from 'redux/slices';
import { triggerDownload } from 'utils/triggerDownload';
import downloadAudioService from 'services/downloadAudioService';

interface TrackParams extends Record<string, string | undefined> {
  id: string;
}

const TrackPageContainer: React.FC = () => {

  const { id } = useParams<TrackParams>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [fetchedSong, setFetchedSong] = useState<any>(null);
  const playingTrackId = useSelector((state: RootState) => state.audio.playingTrackId);
  const stateSong = useSelector((state: RootState) =>
    state.songList.songs.find((song) => song.id === id) 
  );

  const handleDownload = () => {
    if (!song) return;
    downloadAudioService({
      song: song,
      operation: 'audio-only',
    }).then(response => {
      triggerDownload(response);
      console.log('Download link:', response);
    })
      .catch(error => {
        console.error('Download failed', error);
      });
  }

  const handlePlayPause = () => {
    if (id === playingTrackId) {
      dispatch(manageMediaPlayback(id, false))
    } else {
      if(id){
        dispatch(manageMediaPlayback(id, true))
      }
    }
  };

  useEffect(() => {
    if (!stateSong && id) {
      const fetchSong = async (id: string) => {
        try {
          const trackById = await getTrackById(id);
          if (trackById) {
            const mappedSong = mapSongs([trackById])[0];
            setFetchedSong(mappedSong);
          }
        } catch (error) {
          console.error('Failed to fetch the song:', error);
        }
      };

      fetchSong(id);
    }
  }, [stateSong, id, dispatch])



  const song:SongProps = fetchedSong || stateSong;

  //profile image:
  //artists/{artistId}/images/{artistId}.profile.png
  const artistId = song?.artistDetails.artistId
  const backgroundImageUrl = `${config.cdnBaseUrl}/artists/${artistId}/images/main.profile.png`;
 
  const handleTagClick = (tagType: 'genre' | 'mood' | 'instrument', value: string) => {

    dispatch(resetSongList());
    // Navigate to the search/library page
    navigate(`/search/library/${tagType}/${value}`);
  };

  useEffect(() => {
    // Cleanup function to run when the component unmounts
    return () => {
      dispatch(setSelectedSongById(null));
    };
  }, [dispatch]);



  if (!song) {
    return <div className={styles.notFound}>Track not found</div>;
  }

  return (
    <div className={styles.trackDetailsContainer}>
      <div className={styles.backgroundImageContainer} style={{ backgroundImage: ` linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),  url(${backgroundImageUrl})` }}>
        <h1 className={styles.title}>{song.title}</h1>
        <h2 className={styles.artist}>Track by {song.artist}</h2>
        <div className={styles.buttons}>
          {/* <IconButton aria-label="play">
            <PlayArrowIcon style={{ color: 'white' }} onClick={handlePlayPause} />
          </IconButton> */}

          <IconButton aria-label="play">
            {id === playingTrackId ? ( // Check if track is playing
              <PauseIcon style={{ color: 'white' }} onClick={handlePlayPause} />
            ) : (
              <PlayArrowIcon style={{ color: 'white' }} onClick={handlePlayPause} />
            )}
          </IconButton>
          <IconButton aria-label="download">
            <DownloadIcon style={{ color: 'white' }} onClick={handleDownload} />
          </IconButton>
         <FavoriteButton  songProps={song} hideTooltip={true} />
          {/* <IconButton aria-label="favorite">
            <FavoriteIcon style={{ color: 'white' }} />
          </IconButton> */}
          {/* <IconButton aria-label="share">
            <ShareIcon style={{ color: 'white' }} />
          </IconButton> */}
        </div>
      </div>



      <div className={styles.details}>
        {song.album && (
          <div className={styles.albumContainer}>
            <img src={`${config.cdnBaseUrl}/artists/${artistId}/images/${song.albumDetails?.albumId}.cover.png`} alt={`${song.album} cover`} className={styles.albumImage} />
            <div className={styles.albumTextContainer}>
              <div className={styles.albumTitle}>ALBUM</div>
              <div className={styles.albumName}>{song.album}</div>
            </div>
          </div>
        )}
        <div className={styles.detailItem}>
          <h3>Genre</h3>
          <div className={styles.tags}>
            {song.genres.map((genre, index) => (
              <Chip
                key={index}
                label={genre}
                className={styles.tag}
                onClick={() => handleTagClick('genre', genre)}
              />
            ))}
          </div>
        </div>
        <div className={styles.detailItem}>
          <h3>Mood</h3>
          <div className={styles.tags}>
            {song.moods.map((mood, index) => (
              <Chip
                key={index}
                label={mood}
                className={styles.tag}
                onClick={() => handleTagClick('mood', mood)}
              />
            ))}
          </div>
        </div>
        <div className={styles.detailItem}>
          <h3>Instrument </h3>
          <div className={styles.tags}>
            {song.instruments.map((instrument, index) => (
              <Chip
                key={index}
                label={instrument}
                className={styles.tag}
                onClick={() => handleTagClick('instrument', instrument)}
              />
            ))}
          </div>
        </div>
        {song.bpm && (
        <div className={styles.detailItem}>
          <h3>BPM </h3>
          <div className={styles.tags}>
            <Chip label={song.bpm}  className={`${styles.tag} ${styles.tagDisabled}`} />
          </div>
        </div>
        )}
        {/* <div className={styles.detailItem}>
          <h3>Key </h3>
          <div className={styles.tags}>
              <Chip label={song.scale} className={styles.tag} />
          </div>
        </div>

        <div className={styles.detailItem}>
          <h3>Instrumentation </h3>
          <div className={styles.tags}>
           <Chip label={song.instrumentation} className={styles.tag} />
          </div>
        </div> */}
      </div>
      <footer className={styles.footer}>
        <PlayBarComponentBasic song={song} />
      </footer>
    </div>
  );
};

export default TrackPageContainer;
