import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; 
import WarningIcon from '@mui/icons-material/Warning';
import { CustomAlertProps } from 'src/types';


const CustomAlert: React.FC<CustomAlertProps> = ({
    type,
    title,
    message,
    onClose,
    onAction,
    actionLabel,
    loading = false,
    position = { vertical: 'bottom', horizontal: 'right' },
    style = {},
    backgroundColor = 'default',
    textColor = 'default',
    iconColor = 'inherit',
    actionButtonBackgroundColor = 'default',
    alertPadding = '10px',
    showActionButton = true,
    showActionArrowIcon = false


}) => {
    const icons = {
        success: <CheckIcon sx={{ color: iconColor }} />,
        error: <ErrorIcon sx={{ color: iconColor }} />,
        info: <InfoIcon sx={{ color: iconColor }} />,
        warning: <WarningIcon sx={{ color: iconColor }} />
    };
    return (
        <Snackbar
            open
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{
                vertical: position.vertical,
                horizontal: position.horizontal
            }}
            sx={{
                position: 'absolute',
                ...style,
            }}
        >
            <Alert
                severity={type}
                icon={loading ? <CircularProgress size={24} /> : icons[type]}  // Conditionally display loading icon
                action={
                    <>
                        {showActionButton && onAction && actionLabel ? (
                            <Button
                                color="inherit"
                                size="medium"
                                onClick={onAction}
                                sx={{
                                    color: textColor,
                                    backgroundColor: actionButtonBackgroundColor,
                                    '&:hover': {
                                        backgroundColor: actionButtonBackgroundColor
                                    }
                                }}
                            >
                                {actionLabel}
                                {showActionArrowIcon && <ArrowForwardIosIcon fontSize="inherit" sx={{ marginLeft: '4px',marginTop: '-3px' }} />}
                            </Button>
                        ) : null}
                         {onClose && (
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={onClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )}
                    </>
                }
                sx={{ backgroundColor: backgroundColor, color: textColor, padding: alertPadding }}
            >
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomAlert;
