import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { apiSecureClient } from '../../services/apiSecureClient'; //with additional interceptor to refresh token
import { RootState } from 'src/redux/store';
import { resetFilters } from 'redux/slices/';
import { updateSongStates } from 'redux/thunks/songListThunks';
import config from '../../config.json';
import { AppDispatch } from '../store';
import { SongProps } from 'types/types';
import { mapSongs } from '../../utils/songMapping';

// Define a type for the slice state
interface TrackReferenceState {
  name: string | null;
  url: string | null;
  trackReferenceId: string;
  song: SongProps;
  similarTrackResults: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed' | 'noResults';
  error: string | null;
  autoLoad: boolean;
  playTrackReference: boolean;
  selectedTrack: string | null;
}

// Initial state
const initialState: TrackReferenceState = {
  name: null,
  url: null, //replace with trackReference
  similarTrackResults: null,
  status: 'idle',
  error: null,
  autoLoad: true,
  playTrackReference: false,
  selectedTrack: null,
  trackReferenceId: 'original-track', //replace with trackReference
  //Todo: this is a placeholder, replace with trackReference results
  song: {
    id: 'original-track',
    title: 'Reference Track',
    artist: 'You',
    artistId: 'artistId',
    audioPath: '',
    duration: 42,
    bpm: 100,
    genres: [],
    moods: [],
    instruments: [],
    audioUrl: '',
    coverSleeveUrl: '',
    dateCreated: '',
    filename: '',
    artistDetails: {
      artistId: 'artistId',
      artistName: 'artistName',
      description: 'description'
    },
  }
}

function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError === true;
}


/**
 * Fetches similar tracks by URL or ID of the track.
 * @param {object} audioReference - An object that contains either a URL or an ID:
 *   - { url: string } to fetch similar tracks based on the URL of a track.
 *   - { id: string } to fetch similar tracks based on the ID of a track.
 *   When fetching similar tracks for a library track we immediately update the the songList state
 *   with mapped response. When fetching similar tracks for a reference track we defer the update
 *   until the user selects to continue from prompt. Only then the songList state is updated calling
 *   loadSimilarTracksIntoSongState
 */
export const findSimilarTracks = createAsyncThunk(
  'trackReference/findSimilarTracks',
  async (audioReference: { url?: string; song?: SongProps }, { dispatch, getState, rejectWithValue }) => {
    // const payload = audioReference.url ? { url: audioReference.url } : { id: audioReference.song?.id };

   
    
    dispatch(resetFilters()); // reset filters before running a new similarity search

    const payload = audioReference.url
    ? { url: audioReference.url }
    : {
        //adding to the similar track extra params mood and genre to narrow down the search
        id: audioReference.song?.id,
        ...(audioReference.song?.moods?.[0] && { mood: audioReference.song.moods[0] }),
        ...(audioReference.song?.genres?.[0] && { genre: audioReference.song.genres[0] }),
      };

      // Call the API to find similar tracks
     
      const { user } = getState() as RootState;
      const accessToken = user.accessToken;
  
      if (!accessToken) {
        return rejectWithValue('Access token not available');
      }

      try {
        // let similarTracksResponse = await axios.post(config.findSimilarTracksUrl, payload);
        const similarTracksResponse = await apiSecureClient.post(
          config.findSimilarTracksUrl, // Replace with the correct API URL
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`, // Add the Bearer token here
            },
          }
        );
 
      const { trackReference } = getState() as RootState; // Get the current state

      let similarTracksMapped: SongProps[] = [];
      if (audioReference.song) { //if reference is a library track (song props provided in advance), add it to the top (requires mapping first)
         similarTracksMapped = mapSongs(similarTracksResponse.data);
         similarTracksMapped.unshift(audioReference.song);
      }
      //for library tracks, update the song states immediately (logic separated from unshift action on purpose to avoid side effects)
      if (trackReference.autoLoad) { 
        await (dispatch as AppDispatch)(updateSongStates(similarTracksMapped));
      } else {
        //this is a reference track uploaded by user, defer the update of song states to user action
        // console.log('deferring the update of song states to user action');
      }
      return similarTracksResponse.data; // Return the similar tracks result - note this is the unmapped result
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response ? error.response.data : 'Unknown error');
      } else {
        return rejectWithValue('Unknown error');
      }
    }
  }
);

export const loadSimilarTracksIntoSongState = createAsyncThunk(
  'trackReference/loadSimilarTracks',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    if (state.trackReference.similarTrackResults) {
      try {
        // Attempt to update the song states with the similar track results
        const similarTracksMapped = mapSongs(state.trackReference.similarTrackResults);
        await (dispatch as AppDispatch)(updateSongStates(similarTracksMapped));
        // If successful, reset the track reference
        dispatch(resetTrackReference());
      } catch (error) {
        console.error("Failed to update song states:", error);
        // Optionally, handle errors more robustly here (e.g., dispatch an error action)
      }
    }
  }
);


const trackReferenceSlice = createSlice({
  name: 'trackReference',
  initialState,
  reducers: {
    resetTrackReference: (state) => {
      state.similarTrackResults = null;
      state.status = 'idle';
      state.autoLoad = true;
    },
    updateTrackReferenceUrl: (state, action: PayloadAction<string | null>) => {
      state.url = action.payload;
    },
    setAutoLoad: (state, action: PayloadAction<boolean>) => {
      state.autoLoad = action.payload;
    },
    playOriginalTrack: (state, action: PayloadAction<boolean>) => {
      state.playTrackReference = action.payload;
    },
    saveSelectedTrack: (state, action: PayloadAction<string | null>) => {
      state.selectedTrack = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(findSimilarTracks.pending, (state) => {
        // console.log('findSimilarTracks.pending called');
        state.status = 'loading';
      })
      .addCase(findSimilarTracks.fulfilled, (state, action: PayloadAction<any>) => {
        // console.log('findSimilarTracks.fulfilled called, ');
        state.status = action.payload.length > 0? 'succeeded':'noResults';
        state.similarTrackResults =  action.payload;
      })
      .addCase(findSimilarTracks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

// Export actions and reducer
export const { resetTrackReference, updateTrackReferenceUrl, setAutoLoad, playOriginalTrack, saveSelectedTrack } = trackReferenceSlice.actions;
export default trackReferenceSlice.reducer;
