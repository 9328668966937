import React, { useState, useEffect } from 'react';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useAppDispatch } from 'hooks';
import { setVolume } from 'redux/slices';

const iconButtonStyles = {
  color: 'var(--main-colors-white)',
  fontSize: '20px',
  '&:hover': {
    backgroundColor: 'var(--main-colors-black-4)',
  }
};

const VolumeControl: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedSong = useSelector((state: RootState) => state.songList.selectedSong);
  const volumeLevel = useSelector((state: RootState) => state.audio.volumeLevel);
  const [volume, setVolumeState] = useState<number>(0);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [preMutedVolume, setPreMutedVolume] = useState<number>(0);

  useEffect(() => {
    if (selectedSong?.id && volumeLevel) {
      setVolumeState(volumeLevel);
    }
  }, [selectedSong?.id, volumeLevel]);

  const toggleMute = () => {
    const newVolumeLevel = isMuted ? preMutedVolume : 0;
    if(!isMuted){
      setPreMutedVolume(volume);
    }
   
    setVolumeState( newVolumeLevel );
    dispatch(setVolume({ volumeLevel: newVolumeLevel }));
    setIsMuted(!isMuted);
  };

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    const newVolume = newValue as number;
    setVolumeState(newVolume);
    dispatch(setVolume({ volumeLevel: newVolume }));
    if (isMuted) {
      setIsMuted(false);
    }
  };

  const handleVolumeUp = () => {
    const newVolume = Math.min(volume + 10, 100); // Ensure volume does not exceed 100%
    setVolumeState(newVolume);
    dispatch(setVolume({ volumeLevel: newVolume }));
    if (isMuted) {
      setIsMuted(false);
    }
  };

  return (
    <div>
      <Stack
        spacing={0.75}
        direction="row"
        sx={{ mt: 0 }}
        component="div"
      >
        <IconButton aria-label="volume control" sx={iconButtonStyles} onClick={toggleMute}>
          {isMuted ? <VolumeOffIcon sx={iconButtonStyles} /> : <VolumeDown sx={iconButtonStyles} />}
        </IconButton>
        <Slider
          aria-label="Volume"
          value={volume}
          onChange={handleVolumeChange}
          sx={{
            color: 'white',
            '& .MuiSlider-thumb': {
              color: 'white',
              height: 10,
              width: 10,
            },
            '& .MuiSlider-rail': {
              color: 'grey',
              height: 2,
            },
            '& .MuiSlider-track': {
              height: 2,
            },
            width: 75,
            top:3
          }}
        />
        <IconButton aria-label="volume up" sx={iconButtonStyles} onClick={handleVolumeUp}>
          <VolumeUp sx={iconButtonStyles} />
        </IconButton>
      </Stack>
    </div>
  );
};

export default VolumeControl;
