import React from 'react';
import styles from './LandingPage.module.css';

interface VideoThumbnailProps {
  thumbnail: string;
  videoUrl: string;
  onClick: (videoUrl: string) => void;
  align: 'left' | 'right';
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ thumbnail, videoUrl, onClick, align }) => {
    const alignmentClass = align === 'left' ? styles['thumbnail-left'] : styles['thumbnail-right'];
    return (
      <div className={`${styles.thumbnail} ${alignmentClass}`} onClick={() => onClick(videoUrl)}>
        <img src={thumbnail} alt="Thumbnail" style={{ width: '100%', height: '100%' }} />
      </div>
    );
  };

export default VideoThumbnail;
