import styles from './SongDetails.module.css'; // Make 

const SongDetails: React.FC<{ title: string; artist: string, showIcon?: boolean }> = ({ title, artist }) => {
  return (
    <div className={styles.songTitleContainer}>
      <div className={`${styles.textContainer} ${styles.titleSong}`}>
        {title}
      </div>
      <div className={`${styles.textContainer} ${styles.artistName}`}>{artist}</div>
    </div>
  )
};

export default SongDetails;
