import React, { useCallback } from 'react';
import styles from './LandingPage.module.css';
import VideoThumbnail from './VideoThumbnail';
import UploadArea from './UploadAreaClickOnly';
import videoThumbnailUrls from '../../../fixtures/videoThumbnailsFixture.json';

const GalleryAndUploadAction: React.FC<{ onUploadStart: () => void }> = ({ onUploadStart }) => {

  const openFullscreenVideo = useCallback((videoUrl: string = '') => {
    const videoElement = document.createElement('video');
    videoElement.src = videoUrl;
    videoElement.controls = true;
    videoElement.autoplay = false;
    document.body.appendChild(videoElement);
    videoElement.play();

    const removeVideoElement = () => {
      document.body.removeChild(videoElement);
      document.removeEventListener('fullscreenchange', onFullscreenChange);
    };

    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    }

    videoElement.onended = removeVideoElement;

    const onFullscreenChange = () => {
      if (!document.fullscreenElement) {
        removeVideoElement();
      }
    };

    document.addEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  return (
    <div className={styles.layoutContainer}>
      {videoThumbnailUrls.slice(0, 2).map((video, index) => (
        <VideoThumbnail key={index} {...video} onClick={openFullscreenVideo} align="left"/>
      ))} 
      <div className={styles.uploadContainer} onClick={onUploadStart}  > 
        <UploadArea />
      </div>

      {videoThumbnailUrls.slice(2, 4).map((video, index) => (
        <VideoThumbnail key={index} {...video} onClick={openFullscreenVideo} align="right" />
      ))}
    </div>
  );
}

export default GalleryAndUploadAction;