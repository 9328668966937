/**
 * Triggers a file download for the given URL.
 * @param url - The URL of the file to be downloaded.
 * @param filename - The name of the file to save as. If not provided, the filename is determined by the browser or the server.
 */
export function triggerDownload(url: string, filename: string = ''): void {
    // Create an anchor (<a>) element
    const link = document.createElement('a');
  
    link.href = url;
  
    if (filename) {
      link.setAttribute('download', filename);
    }

    document.body.appendChild(link);
  
    link.click();
  
    document.body.removeChild(link);
  }
  