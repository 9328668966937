import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import SongList from '../components/SongList';
import ActionsBar from '../components/ActionsBar';
import PlayBarComponent from '../components/PlayBarComponent';
import DownloadPopup from '../../../components/shared/DownloadPopup';
import SimilaritySearchAlertManager from '../../Alerts/SimilaritySearchAlerts';
import { RootState, AppDispatch } from 'src/redux/store';
import { fetchFiltered, updateSongStates } from 'redux/thunks/songListThunks';
import styles from './MusicLibraryContainer.module.css';
import { selectTotalFilters, resetFilters, setGenresFilter, setMoodsFilter, setInstrumentsFilter } from 'redux/slices/filterSlice';
import { resetDialogs } from 'redux/slices/dialogSlice';
import UploadDialogContainer from '../../../features/UploadDialog/containers/UploadDialogContainer';
import { loadSimilarTracksIntoSongState } from 'redux/slices/trackReferenceSlice';

const MusicLibraryContainer: React.FC = React.memo(() => {
  // console.log('MusicLibraryContainer rendered');

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { tagType, value } = useParams<{ tagType: string; value: string }>();

  // Combining multiple useSelector hooks into one to reduce unnecessary re-renders
  const {
    favoriteTracksDetails,
    songs,
    loading,
    totalFilters,
    lastEvaluatedKey,
    isUploadDialogOpen,
    trackReferenceStatus,
    trackReferenceAutoLoad,
  } = useSelector((state: RootState) => ({
    favoriteTracksDetails: state.favorites.favoriteTracksDetails,
    songs: state.songList.songs,
    loading: state.songList.loading,
    totalFilters: selectTotalFilters(state),
    lastEvaluatedKey: state.songList.lastEvaluatedKey,
    isUploadDialogOpen: state.dialog.uploadDialog,
    trackReferenceStatus: state.trackReference.status,
    trackReferenceAutoLoad: state.trackReference.autoLoad,
  }));

  const isFavoritesPath = location.pathname === '/search/favorites';
  const [deepLinkHandled, setDeepLinkHandled] = useState(false);

  // Combining memoized classes only if totalFilters changes
  const containerClasses = `${styles.container} ${totalFilters > 0 ? styles.expand : ''}`;

  // Function to fetch more songs (scroll pagination)
  const fetchMoreSongs = useCallback(() => {
    if (!loading && lastEvaluatedKey) {
      // console.log('fetchMoreSongs');
      dispatch(fetchFiltered({ lastKey: lastEvaluatedKey }));
    }
  }, [loading, lastEvaluatedKey, dispatch]);

  const actionMap = useMemo(() => ({
    genre: setGenresFilter,
    mood: setMoodsFilter,
    instrument: setInstrumentsFilter,
  }), []);
  
  // Type guard to ensure 'tagType' is a valid key in 'actionMap'
  const isValidTagType = useCallback((key: string): key is keyof typeof actionMap => {
    return key in actionMap;
  }, [actionMap]);
  
  // Handling deep links and song fetching logic with a reduced dependency array
  useEffect(() => {
    if (!isFavoritesPath) {
      dispatch(resetFilters());

      if (!deepLinkHandled && tagType && value && isValidTagType(tagType)) {
        const action = actionMap[tagType]([value]);
        dispatch(action);
        setDeepLinkHandled(true);
        window.history.replaceState(null, '', '/search/library');
      } else if (!tagType && !value) {
        dispatch(fetchFiltered());
      }
    }
  }, [isFavoritesPath, tagType, value, actionMap, deepLinkHandled, dispatch, isValidTagType]);


  useEffect(() => {
    console.log(' isFavoritesPath',favoriteTracksDetails,isFavoritesPath);
    if (isFavoritesPath) {
      dispatch(updateSongStates(favoriteTracksDetails));
    }
  }, [isFavoritesPath, favoriteTracksDetails, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetDialogs());
    };
  }, [dispatch]);

  // Memoize songsToShow only when relevant state changes
  const songsToShow = useMemo(() => (isFavoritesPath ? favoriteTracksDetails : songs), [isFavoritesPath, favoriteTracksDetails, songs]);

  const getNoTracksFoundMessage = useCallback(() => (loading ? '' : 'No tracks found'), [loading]);

  const updateTrackListWithSimilarityResults = useCallback(() => {
    dispatch(loadSimilarTracksIntoSongState());
  }, [dispatch]);

  return (
    <>
      {isUploadDialogOpen && <UploadDialogContainer />}
      <div style={{ backgroundColor: 'var(--main-colors-black-1)' }}>
        {!isFavoritesPath && <ActionsBar />}
        {isFavoritesPath && <div className={styles.favoritesTitle}>Favorites</div>}

        <div className={containerClasses}>
          {songsToShow?.length > 0 ? (
              <SongList songs={songsToShow} autoScrollList={true} fetchMoreSongs={fetchMoreSongs} loading={loading} />
          ) : (
            <div className={styles.noTracksMessage}>
              {isFavoritesPath ? "You haven't saved any music to your favorites." : getNoTracksFoundMessage()}
            </div>
          )}
        </div>

        <footer className={styles.footer}>
          {songsToShow.length > 0 && <PlayBarComponent />}
        </footer>

        <DownloadPopup />
        {loading && (
          <div className={styles.loadingSpinner}>
            <CircularProgress color="inherit" />
          </div>
        )}

        {trackReferenceStatus === 'loading' && <SimilaritySearchAlertManager trackReferenceStatus="loading" />}
        {trackReferenceStatus === 'succeeded' && !trackReferenceAutoLoad && (
          <SimilaritySearchAlertManager trackReferenceStatus="succeeded" onAction={updateTrackListWithSimilarityResults} />
        )}
        {trackReferenceStatus === 'failed' && <SimilaritySearchAlertManager trackReferenceStatus="failed" />}
        {trackReferenceStatus === 'noResults' && <SimilaritySearchAlertManager trackReferenceStatus="noResults" />}
      </div>
    </>
  );
});

export default MusicLibraryContainer;
