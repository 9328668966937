import { AppThunk } from '../store'; // Adjust the import path as per your project structure
import { RootState } from '../store';
import {
  playTrack,
  stopTrack,
  playVideo,
  pauseVideo,
  setSelectedSongById,
  setSelectedSong,
  saveSelectedTrack,
  playOriginalTrack
} from '../slices';

export const manageMediaPlayback = (songId: string | null, play?: boolean): AppThunk =>
  (dispatch, getState: () => RootState) => {
    const { audio } = getState();
    const { trackReference } = getState();

    if (!songId) { //when audio finishes playing
      if (audio.playingTrackId) {
        dispatch(stopTrack());
        dispatch(pauseVideo());
      }
      return;
    }

    if (play) {//play the audio and video
      if (audio.playingTrackId && audio.playingTrackId !== songId) {
        dispatch(stopTrack());
      }
      //check if play-original track is active
      if (trackReference.playTrackReference) {
        //check if this is an original track if not 
        if (songId !== trackReference.trackReferenceId ) {
            dispatch(playOriginalTrack(false));
            dispatch(saveSelectedTrack(null));
            dispatch(setSelectedSongById(songId));
        } else {
          dispatch(setSelectedSong({ id: songId, song: trackReference.song }));
        }

      } else {
        dispatch(setSelectedSongById(songId));
      }

      dispatch(playTrack(songId));
      dispatch(playVideo());

    } else {// pause the audio and video
      dispatch(stopTrack());
      dispatch(pauseVideo());
    }
  };