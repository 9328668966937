// ProfileMenu.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, List, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
// import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
// import ExtensionIcon from '@mui/icons-material/Extension';
// import SettingsIcon from '@mui/icons-material/Settings';
// import BusinessIcon from '@mui/icons-material/Business';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { SxProps, Theme } from '@mui/material/styles';

interface MenuItemProps {
  icon: React.ElementType<SvgIconProps>;
  label: string;
  onClick?: () => void;
  sx?: SxProps<Theme>; // Add this line
}


const MenuItem: React.FC<MenuItemProps> = ({ icon: Icon, label, onClick, sx }) => (
  <ListItemButton sx={{ padding: '2px 16px', ...sx }} onClick={onClick}> {/* Apply sx prop */}
    <ListItemIcon sx={{ minWidth: '30px' }}>
      <Icon className='icon-gray' sx={{ mr:2,fontSize: '1.25rem' }} />
    </ListItemIcon>
    <ListItemText primary={label} sx={{
      '.MuiTypography-root': { fontSize: '0.875rem' },
      margin: '0px'
    }} />
  </ListItemButton>
);

interface ProfileMenuProps {
  username: string;
  email: string;
  onLogout: () => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ username, email, onLogout }) => {

  const navigate = useNavigate();

  const menuItems = [

    
    // Example Client Menu
    // { icon: LibraryMusicIcon, label: 'Projects', onClick: () => { } },
    // { icon: ExtensionIcon, label: 'Plugins', onClick: () => { } },
    // { icon: BusinessIcon, label: 'Billing', onClick: () => { } },
    // { icon: AccountBoxIcon, label: 'Account', onClick: () => { } },
    // { icon: ExitToAppIcon, label: 'Sign Out', onClick: onLogout },


    //Example Composer Menu
    // { icon: LibraryMusicIcon, label: 'My Music', onClick: () => { } },
    // { icon: BusinessIcon, label: 'Business', onClick: () => { } },
    // { icon: AccountBoxIcon, label: 'Account', onClick: () => { } },
    // { icon: ExitToAppIcon, label: 'Sign Out', onClick: onLogout },

    //Example User Menu
   { icon: FavoriteIcon, label: 'Favorites', onClick:()=>navigate('/search/favorites')},
   { icon: ExitToAppIcon, label: 'Sign Out', onClick: onLogout },

  ];

  return (
    <Paper sx={{ zIndex:'2', position: 'absolute', top: '110%', right: 25, backgroundColor: 'var(--main-colors-black-3)', color: 'rgba(var(--main-colors-white-rgb), 0.87)', width: '160px'}}>
      <List component="nav">
        <ListItemButton sx={{ padding: '4px 16px' }}>
          <ListItemText primary={username} secondary={email} sx={{ '.MuiListItemText-primary': { color: 'rgba(var(--main-colors-white-rgb), 0.87)', fontSize: '14px' }, '.MuiListItemText-secondary': { color: 'rgba(255, 255, 255, 0.50)', fontSize: '10px' } }} />
        </ListItemButton>
        <Divider sx={{ mb:0.5,height: '1px'   ,borderColor: "rgba(99,95,94,0.5)"}} />
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {index === menuItems.length - 1 && <Divider sx={{ mt: 1, mb:-1,height: '1px'   ,borderColor: "rgba(99,95,94,0.5)"}} />}
            <MenuItem
              {...item}
              sx={index === menuItems.length - 1 ? { mt: 2 } : {}}
            />
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default ProfileMenu;
