import React from 'react';
import styles from './ErrorPage.module.css';

const ErrorPage: React.FC = () => {
    return (
        <div className={styles.pageContainer}>
            <>
                <div className={styles.headerTitle}> Oops!</div>
                <div className={styles.subTitle}>We can't seem to find the page you're looking for.</div>
                <div className={styles.errorCodeTitle}>  Error code: 404</div>
            </>
        </div>
    );
};

export default ErrorPage;