import React, { FC } from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import strings from "../../utils/strings";
interface MenuButtonProps {
  isSelected: boolean;
}

const MenuButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<MenuButtonProps>(({ theme, isSelected }) => ({
  color: '#9e9e9e',
  borderRadius: '4px',
  height: '36px',
  marginRight: '10px',
  '&:hover': {
    color: 'rgba(255, 255, 255, 0.8)',
    backgroundColor: 'rgba(158, 158, 158, 0.12)',
  },
  ...(isSelected && {
    color: '#ffffff',
    backgroundColor: '#272727',
  }),
}));

const MainNavigation: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.pathname.split('/')[2]
  
  type MenuName = typeof strings.mainMenu[keyof typeof strings.mainMenu];
  const handleButtonClick = (menuName: MenuName) => {
    switch (menuName) {
      case strings.mainMenu.home:
        navigate('/');
        break;
      case strings.mainMenu.musicLibrary:
        navigate('/search/library');
        break;
      default:
        navigate('/search/library');
        break;
    }
  };

  return (
    <div>
      <MenuButton isSelected={!type} onClick={() => handleButtonClick(strings.mainMenu.home)}>
        {strings.mainMenu.home}
      </MenuButton>
      <MenuButton isSelected={type === 'library'} onClick={() => handleButtonClick(strings.mainMenu.musicLibrary)}>
        {strings.mainMenu.musicLibrary}
      </MenuButton>
 
    </div>
  );
};

export default MainNavigation;
