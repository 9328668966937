// Import createSlice and PayloadAction from Redux Toolkit
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface DownloadState {
  isLoading: boolean;
  progress: number;
  downloadLink: string | null;
  error: string | null;
}

// Define the initial state using that type
const initialState: DownloadState = {
  isLoading: false,
  progress: 0,
  downloadLink: null,
  error: null,
};

export const downloadSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    startDownload: (state) => {
      state.isLoading = true;
      state.progress = 0;
      state.downloadLink = null;
      state.error = null;
    },
    updateProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    downloadSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.downloadLink = action.payload;
    },
    downloadFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetDownload: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { startDownload, updateProgress, downloadSuccess, downloadFailure, resetDownload } = downloadSlice.actions;

// Selectors
export const selectDownloadState = (state: { download: DownloadState }) => state.download;

// The reducer
export default downloadSlice.reducer;
