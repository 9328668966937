import React, { useEffect, useState } from 'react';
import styles from '../components/UploadDialog.module.css';
import { progressUpdateParams } from '../../../types/state';

interface UploadingContentProps {
  onClose?: () => void;
  progress: progressUpdateParams;
  file: {
    name: string;
    size: number;
    type: string;
  };
}

const UploadProgress: React.FC<UploadingContentProps> = ({ progress, file }) => {
  const [message, setMessage] = useState('');
  useEffect(() => {
    if (progress.value >= 100 && file.type.startsWith('audio/')) {
    } else if (progress.value >= 100 && file.type.startsWith('video/') && progress.action === 'upload') {
    } else if (progress.value >= 100 && file.type.startsWith('video/') && progress.action === 'processing') {
    } else {
      //this is the upload progress
      const fileSizeStr = `${(file.size / (1024 * 1024)).toFixed(0)} MB`;
      const progressStr = Math.round(progress.value) + '%';
      const messageText = progress.action !== 'processing' ? `${fileSizeStr} Uploading ${progressStr}` : `Almost done, just a couple more seconds...`;
      setMessage(messageText);
    }
  }, [progress]);

  return (

    <div className={styles.uploadProgressContainer}>
      {file ? (
        <>
          {progress.action !== 'processing' && (
            <div className={styles.fileName}>{file.name}</div>)
          }
          {message &&
            <div className={styles.fileInfo}>
              {message}
            </div>
          }
          <div className={styles.progressBar}>
            <div className={styles.progress} style={{ width: progress.action === 'processing' ? '100%' : `${Math.round(progress.value)}%` }}></div>
          </div>
        </>
      ) : (
        <>
          Loading...
        </>
      )
      }
      {/* {progress.action !== 'processing' && ( //Todo: add logic to close button in parent
        <div className={styles.closeButton} onClick={onClose}>&times;</div>)
      } */}
    </div>
  );
};

export default UploadProgress;
