import styled from 'styled-components';
import { capitalize } from 'lodash';
import { Chip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setGenresFilter, setMoodsFilter, setInstrumentsFilter, resetFilters } from 'redux/slices';

const GENRES_LIMIT = 3;
const MOODS_LIMIT = 2;
const INSTRUMENTS_LIMIT = 2;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`;

const TagsContainer = styled(Container)`
  padding: 28px 16px;
  box-sizing: border-box;
  height: auto;
  overflow: hidden;
  width: 280px;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledChip = styled(Chip)`
  && {
    color: white;
    background: rgba(255, 255, 255, 0.18);
    border-radius: 100px;
    font-size: 11px;
    height: 14px;
    max-width: calc(50% - 4px); // Adjust if chips have additional padding or border
    flex: 0 0 auto; // Prevent chips from growing or shrinking
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
`;

const TagsList: React.FC<{ genres: string[], moods: string[], instruments: string[] }> = ({ genres, moods, instruments }) => {
  const dispatch = useDispatch();

  const handleTagClick = (tagType: 'genre' | 'mood' | 'instrument', value: string) => {
    
    const actionMap = {
      genre: setGenresFilter,
      mood: setMoodsFilter,
      instrument: setInstrumentsFilter,
    };

    dispatch(resetFilters());
    dispatch(actionMap[tagType]([value]));
  };

  return (
    <TagsContainer>
      {genres.slice(0, GENRES_LIMIT).map((genre, index) => (
        <StyledChip
          key={`genre-${index}`}
          label={capitalize(genre)}
          onClick={() => handleTagClick('genre', genre)}
        />
      ))}
      {moods.slice(0, MOODS_LIMIT).map((mood, index) => (
        <StyledChip
          key={`mood-${index}`}
          label={capitalize(mood)}
          onClick={() => handleTagClick('mood', mood)}
        />
      ))}
      {instruments.slice(0, INSTRUMENTS_LIMIT).map((instrument, index) => (
        <StyledChip
          key={`instrument-${index}`}
          label={capitalize(instrument)}
          onClick={() => handleTagClick('instrument', instrument)}
        />
      ))}
    </TagsContainer>
  );
};

export default TagsList;
