// /src/utils/strings/index.ts

interface StringsStructure {
    mainMenu: {
        home: string;
        musicLibrary: string;
        favorites: string;
    }
    errorMessages: {
        networkError: string;
        pageNotFound: string;
    };
    buttonLabels: {
        submit: string;
        cancel: string;
    }
}

const strings: StringsStructure = {
    mainMenu: {
        home: "HOME",
        musicLibrary: "MUSIC",
        favorites: "FAVORITES",
    },
    errorMessages: {
        networkError: "There was a problem reaching the server. Please try again.",
        pageNotFound: "The page you're looking for doesn't exist.",
    },
    buttonLabels: {
        submit: "Submit",
        cancel: "Cancel",
    }
};

export default strings;
