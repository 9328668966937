// CustomCloseIcon.tsx
import React from 'react';

const CustomCloseIcon: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <svg
    width="12" // Custom width
    height="12" // Custom height
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M18 6L6 18M6 6l12 12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CustomCloseIcon;
