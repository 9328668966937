
// CustomVideoIconOpen.tsx
import React from 'react';

const CustomVideoIconOpen: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
<svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7V2H2L2 9L2 14H11L20 14V7ZM11 16H2C0.9 16 0 15.1 0 14V9V2C0 0.900001 0.9 0 2 0H11H20C20.0688 0 20.1367 0.00351563 20.2037 0.010376C21.209 0.113281 22 0.968751 22 2V7V14C22 15.1 21.1 16 20 16H11ZM14 8L8 12V4L14 8Z"
        fill="white"
    />
</svg>
)

export default CustomVideoIconOpen;