import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import styles from './ProgressBar.module.css'; // Import the styles

const ProgressBar: React.FC = () => {
    const videoState = useSelector((state: RootState) => state.video);

    const progress = videoState.videoDuration > 0
        ? (videoState.videoProgress / videoState.videoDuration) * 100
        : 0;

    return (
        <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{ width: `${progress}%` }}></div>
        </div>
    );
};

export default ProgressBar;
