import {useState} from 'react';
import CustomAlert from '../../components/shared/CustomAlert';  // Adjust the path as necessary
import { CustomAlertProps } from 'src/types';

interface SimilaritySearchAlertsProps {
  trackReferenceStatus: 'loading' | 'succeeded' | 'failed' | 'idle' | 'noResults';
  onAction?: () => void; 
}

const SimilaritySearchAlerts: React.FC<SimilaritySearchAlertsProps> = ({ trackReferenceStatus, onAction }) => {


  const [isAlertVisible, setIsAlertVisible] = useState(true);

  const handleClose = () => {
    setIsAlertVisible(false);
    console.log("Alert closed");
  };

  if (!isAlertVisible) {
    return null;
  }

  let alertProps: CustomAlertProps = {
    type: 'info',
    title: 'Notification',
    message: 'Status unknown', 
    textColor: 'default',
    backgroundColor: 'default',
    iconColor: 'inherit',
    actionButtonBackgroundColor: 'default',
    showActionButton: true,
    loading: false,
    style: { maxWidth: '616', maxHeight: '108', marginBottom: '80px' },
    alertPadding: '15px 20px 15px 20px',
    position: { vertical: 'bottom', horizontal: 'right' },
    
  };

  switch (trackReferenceStatus) {
    case 'loading':
      alertProps = {
        ...alertProps,
        type: 'success',
        title: 'Looking for similar tracks',
        message: 'While you wait, you can continue listening to library tracks',
        actionLabel: 'Continue',
        onAction: onAction ? onAction : () => console.log('Default loading Action if not provided'),
        backgroundColor: '#1A1A1A',
        textColor: 'white',
        showActionButton: false,
        loading: true,
      };
      break;
      case 'noResults':
        alertProps = {
          ...alertProps,
          type: 'info',
          title: 'Sorry, no similar tracks found',
          message: 'We are constantly updating our library. Please try again soon.',
          backgroundColor: 'rgba(var(--main-colors-cyan-light-1-rgb),0.9)',
          onClose: handleClose,
          textColor: 'black',
          iconColor: 'black',
          actionButtonBackgroundColor: 'rgba(var(--main-colors-white-rgb),0.6)',
        };
        break;
    case 'succeeded':
      alertProps = {
        ...alertProps,
        type: 'success',
        title: 'Similarity Search Complete',
        message: 'Continue to update the library with new tracks',
        actionLabel: 'Continue',
        onAction: onAction ? onAction : () => console.log('Default Action Success'),
        backgroundColor: 'rgba(var(--main-colors-cyan-light-1-rgb),0.9)',
        textColor: 'black',
        iconColor: 'black',
        actionButtonBackgroundColor: 'rgba(var(--main-colors-white-rgb),0.6)',
        showActionArrowIcon: true,
      };
      break;
    case 'failed':
      alertProps = {
        ...alertProps,
        type: 'error',
        message: 'The operation failed. Please try again.',
        onClose: handleClose,
      };
      break;
    // No need for a default case if 'idle' or others revert to initial values
  }

  return <CustomAlert {...alertProps} />;
};

export default SimilaritySearchAlerts;
