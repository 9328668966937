import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CustomVideoIconClose from '../../../components/shared/CustomVideoIconClose';
import CustomVideoIconOpen from '../../../components/shared/CustomVideoIconOpen';

const iconButtonStyles = {
  color: 'var(--main-colors-white)',
  margin: '7px',
  '&:hover': {
    backgroundColor: 'var(--main-colors-black-4)',
  }
};

interface VideoButtonProps extends IconButtonProps {
  onVideo: () => void;
  isOpen: boolean; // Add the isOpen prop to determine the icon state
}

const VideoButton = React.forwardRef<HTMLButtonElement, VideoButtonProps>(({ onVideo, isOpen, ...props }, ref) => {
  return (
    <IconButton {...props} aria-label={isOpen ? "close video" : "open video"} sx={{ ...iconButtonStyles, ...props.sx }} onClick={onVideo} ref={ref}>
      {isOpen ? <CustomVideoIconClose /> : <CustomVideoIconOpen />}
    </IconButton>
  );
});

export default VideoButton;
