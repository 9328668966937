import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SongProps } from 'src/types';
import { fetchFiltered } from '../thunks/songListThunks';

interface SongListState {
  songs: SongProps[];
  selectedSongId: string | null;
  selectedSong: SongProps | null;
  loading: boolean;
  bin?: string[];  // Optional bin for storing song IDs (e.g., for deleting songs)
  lastEvaluatedKey?: Record<string, any> | undefined; // Optional key used for pagination in fetching songs
  pageLimit: number; // Number of songs to fetch per page
}

interface SetSelectedSongPayload {
  id: string;
  song: SongProps;
}

// Initial state for the song list slice
const initialSongListState: SongListState = {
  songs: [],
  selectedSongId: null,
  selectedSong: null,
  loading: false,
  pageLimit: 25, // Set the default page limit to 10
  lastEvaluatedKey: undefined, // Initialize the lastEvaluatedKey to null
};

// Create a Redux slice for managing the song list state
const songListSlice = createSlice({
  name: 'songList', // Name of the slice
  initialState: initialSongListState, // Initial state of the slice
  reducers: {
    // Reducer to load a new set of songs into the state
    loadSongs: (state, action: PayloadAction<SongProps[]>) => {
      // console.log('loadSongs ', action.payload);
      state.selectedSongId = null;
      state.songs = action.payload;
    },

    // Reducer to set the selected song ID and song object
    setSelectedSong: (state, action: PayloadAction<SetSelectedSongPayload>) => {
      const { id, song } = action.payload;
      state.selectedSongId = id;
      state.selectedSong = song;
    },

    // Reducer to set the selected song by its ID 
    setSelectedSongById: (state, action: PayloadAction<string | null>) => {
      state.selectedSongId = action.payload;
      const selectedSong = state.songs.find(song => song.id === action.payload);
      state.selectedSong = selectedSong || null;
    },

    // Reducer to add song IDs to the bin (e.g., for deletion or archiving)
    addToBin: (state, action: PayloadAction<string[]>) => {
      if (!state.bin) {
        state.bin = [];
      }
      state.bin = [...state.bin, ...action.payload];
    },

    // Reducer to remove a song ID from the bin
    removeFromBin: (state, action: PayloadAction<string>) => {
      if (state.bin) {
        state.bin = state.bin.filter(item => item !== action.payload);
      }
    },
    resetLastEvaluatedKey: (state) => {
      // console.log('resetLastEvaluatedKey');
      state.lastEvaluatedKey = undefined;
    },

    // Reducer to reset the state to its initial state
    resetSongList: () => initialSongListState,

    setSongsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

  },

  // Handle additional actions, particularly for async thunks
  extraReducers: (builder) => {
    builder
      // Handle the pending state of the fetchFiltered thunk
      .addCase(fetchFiltered.pending, (state) => {
        console.log('fetchFiltered.pending');
        state.loading = true; // Set loading to true while fetching filtered songs
      })
      // Handle the fulfilled state of the fetchFiltered thunk
      .addCase(fetchFiltered.fulfilled, (state, action) => {
        if (!action.payload) return;  // Return if no payload is provided
        console.log('lastEvaluatedKey', action.payload.lastEvaluatedKey);
        // state.selectedSongId = null;
        const { data, lastEvaluatedKey } = action.payload; // Extract data and pagination key from payload
        // Assign an index to each song based on the current length of state.songs. This is important also for pagination
        // as items are rendered not necessarily in the order they are fetched (see intersection observer) it is important to keep track of the index)
        const startIndex = state.songs.length;
        const songsWithIndex = data.map((song, index) => ({
          ...song,
          index: startIndex + index, // Assign incremental index to each song
        }));

        // Update the state with new songs including the index
        state.songs = [...state.songs, ...songsWithIndex];
        state.lastEvaluatedKey = lastEvaluatedKey || undefined; // Update the pagination key in the state
        state.loading = false; // Stop loading once the filtered songs are fetched
      })

      // Handle the rejected state of the fetchFiltered thunk
      .addCase(fetchFiltered.rejected, (state) => {
        state.loading = false; // Stop loading if the fetchFiltered action fails
      });
  },
});

// Export actions generated by createSlice for use in components
export const { loadSongs, setSelectedSongById, setSelectedSong, addToBin, removeFromBin, resetLastEvaluatedKey, resetSongList, setSongsLoading } = songListSlice.actions;
// Export the reducer to be included in the store
export default songListSlice.reducer;
