import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VideoState {
  videoOpen: boolean;
  videoDuration: number;
  isPlaying: boolean;
  currentVideoTime: number;
  lastUpdatedBy: 'audio' | 'video' | null;
  videoOffsetInSeconds: number;
  videoInRange: boolean;
  videoProgress: number;
  videoAvailable: boolean;
}

const initialVideoState: VideoState = {
  videoOpen: false,
  videoDuration: 0,
  isPlaying: false,
  currentVideoTime: 0,
  lastUpdatedBy: null,
  videoOffsetInSeconds: 0,
  videoInRange: true,
  videoProgress: 0,
  videoAvailable: false,
};

export const videoSlice = createSlice({
  name: 'video',
  initialState: initialVideoState,
  reducers: {
    openVideo: (state) => {
      state.videoOpen = true;
    },
    exitVideo: (state) => {
      state.videoOpen = false;
    },
    setVideoDuration: (state, action: PayloadAction<number>) => {
      state.videoDuration = action.payload;
    },
    setVideoInRange: (state, action: PayloadAction<boolean>) => {
      state.videoInRange = action.payload;
    },
    playVideo: state => {
      state.isPlaying = true;
      state.lastUpdatedBy = 'video';
    },
    pauseVideo: state => {
      console.log('pausing video state current state ', state.isPlaying);
      state.isPlaying = false;
    },
    setVideoOffsetInSeconds: (state, action: PayloadAction<number>) => {
      state.videoOffsetInSeconds = action.payload;
    },
    updateVideoProgress: (state, action: PayloadAction<number>) => {
      state.videoProgress = action.payload;
    },
    setVideoAvailable: (state, action: PayloadAction<boolean>) => {
      state.videoAvailable = action.payload;
    }
  },
});


export const { openVideo, exitVideo, setVideoDuration, playVideo, pauseVideo, setVideoOffsetInSeconds, setVideoInRange, updateVideoProgress, setVideoAvailable } = videoSlice.actions;
export default videoSlice.reducer;
