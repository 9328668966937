import styled from 'styled-components';
import Cover1Image from 'assets/images/SongList_Cover.png';
import {  useSelector } from 'react-redux';
import { manageMediaPlayback } from 'redux/thunks/manageMediaPlaybackThunks';
import { RootState } from 'redux/store';
import PlayPauseButton from './PlayPauseButton';
import { ArtCoverComponentProps } from 'src/types';
import { Skeleton } from '@mui/material';
import { useAppDispatch } from 'hooks';

const ArtCoverContainer = styled.div`
  width: 72px;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ArtCover = styled.img`
  height: 48px;
  width: 48px;
  object-fit: cover;
`;


const ArtCoverComponent: React.FC<ArtCoverComponentProps> = ({ hovered, id, isLoading, coverSleeveUrl }) => {
  const dispatch =  useAppDispatch();
  const playingTrackId = useSelector((state: RootState) => state.audio.playingTrackId);

  const handlePlayPause = () => {
    if (playingTrackId === `${id}` ) {
      console.log('handlePlayPause manageMediaPlayback false', id, playingTrackId)
      dispatch(manageMediaPlayback(id, false))
       //if playing track is paused OR not the current track, its playingTrackId will be null
    } else {
      console.log('handlePlayPause manageMediaPlayback true', id, playingTrackId)
      dispatch(manageMediaPlayback(id, true))
    }
  };

  const isPlaying = playingTrackId === `${id}`;
  return (
    <ArtCoverContainer>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          height={50}
          width="100%"
          animation="wave"
          sx={{ bgcolor: 'var(--main-colors-black-2)' }}
        />
      ) : (
        <>
          <ArtCover src={coverSleeveUrl?coverSleeveUrl:Cover1Image} loading="lazy" alt="Cover" />
          {hovered && (
            <PlayPauseButton isPlaying={isPlaying} handlePlayPause={handlePlayPause} />
          )}
        </>
      )}
    </ArtCoverContainer>
  );
  
};

export default ArtCoverComponent;
