// src/services/apiSecureClient.ts 
//see auth-token-flow.md for more details

import axios from 'axios';
import config from '../config.json';
import { refreshAccessToken } from '../redux/slices/userSlice'; // Adjust import
import store from '../redux/store';

// Create Axios instance
export const apiSecureClient = axios.create({
  baseURL: 'https://api.monstermusic.io',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor: Attach access token to requests
apiSecureClient.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('MonsterMusicIo.accessToken'); // Retrieve from state if needed

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

// Response interceptor: Handle token expiration
apiSecureClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      try {
        // Call the refresh token endpoint (the browser will automatically include the HTTP-only refresh token cookie)
        const { data } = await axios.post(config.refreshTokenUrl);

        const newAccessToken = data.accessToken;

        // Update access token in localStorage and Redux
        localStorage.setItem('MonsterMusicIo.accessToken', newAccessToken);
        store.dispatch(refreshAccessToken(newAccessToken)); // Update Redux state if needed

        // Retry original request with the new token
        error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return apiSecureClient.request(error.config);
      } catch (refreshError) {
        console.error('Refresh token failed:', refreshError);
        localStorage.clear();
        window.location.href = '/login'; // Redirect to login on failure
      }
    }

    return Promise.reject(error);
  }
);



