// s3UploadService.ts

import WebSocketService from './WebSocketService';
import store from '../redux/store';
import { setVideoLink, updateTrackReferenceUrl, setUploadedFile,setRawUploadedVideoS3Key } from '../redux/slices/'; // Adjust imports as necessary
import config from '../config.json';
import {progressUpdateParams} from '../types/state';

type PresignedUrlParams = {
    fileName: string;
    fileType: string;
    connectionId: string;
    clientId: string;
    clientTimestamp: string;
};

async function getPresignedUrl(params: PresignedUrlParams) {
    try {
        const response = await fetch(config.uploadServiceURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error getting pre-signed URL:', error);
        throw error;
    }
}


async function callProcessMediaService({ connectionId, clientId, clientTimestamp }: { connectionId: string, clientId: string, clientTimestamp: string }) {
    const payload = {
        connectionId,
        clientId,
        clientTimestamp,
    };

    try {
        const response = await fetch(config.processMediaServiceUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error(`Error from processMediaService: ${response.statusText}`);
        }

        // Handle response from processMediaService
        const responseData = await response.json();
        // console.log('ProcessMediaService response:', responseData);
        return responseData;
    } catch (error) {
        console.error('Error calling processMediaService:', error);
        throw error; // Rethrow to handle it in the calling context if needed
    }
}

//Todo: need refactoring
async function uploadFileToS3(file: File, clientId: string, onProgressUpdate: (progress:progressUpdateParams) => void) {
    
    let sanitizedFilename = file.name.replace(/[^a-z0-9.]/gi, '_').toLowerCase();

  // Create a new File object with the sanitized filename
    let sanitizedFile = new File([file], sanitizedFilename, {
        type: file.type,
        lastModified: file.lastModified,
    });
    
    store.dispatch(setUploadedFile({ name: sanitizedFile.name, size: file.size, type: file.type }));
    const clientTimestamp = Date.now().toString();
    const webSocketUrl = `${config.webSocketUrl}?clientId=${clientId}&t=${clientTimestamp}`;
    await WebSocketService.openConnection(webSocketUrl);

    let resolveUploadPromise: (result: { message: string, referenceUrl?: string }) => void;
    let rejectUploadPromise: (reason?: any) => void;

    const uploadPromise = new Promise<{ message: string, referenceUrl?: string }>((resolve, reject) => {
    resolveUploadPromise = resolve;
    rejectUploadPromise = reject;
    });
    
    //socket is listening for messages from the server (video encoding and audio extraction service)
    WebSocketService.listenForMessages(
        (message) => {
            // console.log('incoming socket message ...', message);
            if (message.videoUrl && typeof message.videoUrl === 'string') {
                if (message.videoUrl.endsWith('.mp4') && message.s3KeyOriginalFile) { //video encoding and audio extraction service completed
                    store.dispatch(setRawUploadedVideoS3Key(message.s3KeyOriginalFile)); // will be used when downloading the video (original file)
                    store.dispatch(setVideoLink(message.videoUrl)); //used for playing the video SD version in-app
                    store.dispatch(updateTrackReferenceUrl(message.audioUrl || null));
                    console.log('Video and Audio processed successfully!');
                    console.log('audioUploadedUrl ',response.audioUploadedUrl);
                    // console.log('videoUrl ',response.videoUrl);
                    

                }
                //this would be the audio and video
                resolveUploadPromise({ message: 'resolveUploadPromise: Video and Audio processed successfully!', referenceUrl: message.audioUrl});
               //Todo: keep connection open for similar songs service
                WebSocketService.closeConnection();
            } else if (message.progress && typeof message.progress === 'number') {
                //video processing ...
                onProgressUpdate({value:message.progress,action:'processing'});
               //console.log('Progress pf media processing:', message.progress);
            }
        },
        (errorMessage) => {
            console.error('WebSocket error:', errorMessage);
            rejectUploadPromise(errorMessage);
        }
    );

    const connectionId = await WebSocketService.fetchConnectionIdHTTP(clientId, clientTimestamp);
    // console.log(`WebSocket connection established with ID: ${connectionId}`);

    //request pre signed upload url
    const presignedUrlParams: PresignedUrlParams = {
        fileName: sanitizedFile.name,
        fileType: file.type,
        connectionId,
        clientId,
        clientTimestamp,
    };
    const response = await getPresignedUrl(presignedUrlParams);
    const url = response.url;
    let xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;
            onProgressUpdate({value:progress,action:'upload'});
        }
    };

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', file.type);

    xhr.onload = async () => {
        if (xhr.status === 200) {

            if (file.type.startsWith('audio')) {
                //console.log('Audio uploaded successfully, audioUploadedUrl = ', response.audioUploadedUrl);
                store.dispatch(updateTrackReferenceUrl(response.audioUploadedUrl)); //for video we need to wait for extraction
                resolveUploadPromise({message:'resolveUploadPromise: AUDIO uploaded successfully!', referenceUrl: response.audioUploadedUrl});
                return;
            }
            try {
                await callProcessMediaService({ connectionId, clientId, clientTimestamp });

                // console.log('Process media service has been called successfully.');
            } catch (error) {
                console.error('Error calling process media service:', error);
            }
        } else {
            rejectUploadPromise('Upload failed');
        }
    };

    xhr.onerror = () => {
        rejectUploadPromise('XHR error occurred');
    };

    xhr.send(file);

    return {
        uploadPromise,
        cancelUpload: () => {
            console.log('Upload canceled');
            xhr.abort();
            WebSocketService.closeConnection();
            rejectUploadPromise('Upload canceled by the user');
        }
    };
}

export { getPresignedUrl, uploadFileToS3 };