// WebSocketService.ts
import config from '../config.json';
import axios from 'axios';

interface GenericMessage {
    type?:  'message' | 'error' ;
    [key: string]: any;
    errorMessage?: string;
  }
  
  class WebSocketService {
    private ws: WebSocket | null = null;

    async fetchConnectionIdHTTP(clientId: string, clientTimestamp: string): Promise<string> {
      try {
          const response = await axios.post(config.fetchConnectionIdUrl, {
              clientId,
              clientTimestamp,
          });

          // Assuming your endpoint responds with JSON that includes the connectionId
          return response.data.connectionId;
      } catch (error) {
          console.error('Failed to fetch connection ID via HTTP:', error);
          throw new Error('Failed to fetch connection ID');
      }
  }

  
    openConnection(wsUrl: string): Promise<WebSocket> {
      return new Promise((resolve, reject) => {
        const ws = new WebSocket(wsUrl);
  
        ws.onopen = () => {
          console.log('WebSocket connection opened');
          this.ws = ws;
          resolve(ws);
        };
  
        ws.onerror = (event) => {
          console.error('WebSocket connection error:', event);
          this.ws = null; // Ensure ws is null if the connection couldn't be established
          reject(event);
        };
      });
    }
  
    listenForMessages(
      onMessage: (data: { [key: string]: any }) => void,
      onError: (errorMessage: string) => void
    ): void {
      if (!this.ws) {
        console.error('WebSocket connection not established');
        return;
      }
      this.ws.onmessage = (event) => {;
        const data: GenericMessage = JSON.parse(event.data);
        // console.log('data message incoming from socket ',data)
        if (data.type === 'message') {
          onMessage(data);
        } else if (data.type === 'error' && data.errorMessage) {
          onError(data.errorMessage);
        }  
      };
  
      this.ws.onerror = (event) => {
        console.error('WebSocket error occurred:', event);
        onError('WebSocket error occurred');
      };
  
      this.ws.onclose = () => {
        console.log('WebSocket connection closed');
        this.ws = null;
      };
    }
  
   
  
    closeConnection(): void {
      if (this.ws) {
        this.ws.close();
        this.ws = null;
      }
    }
  
    sendMessage(message: object): void {
      if (!this.ws) {
        console.error('WebSocket connection not established');
        return;
      }
  
      this.ws.send(JSON.stringify(message));
    }
  }
  
  const webSocketService = new WebSocketService();
  export default webSocketService;
  