import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import styles from './VideoInfo.module.css';

const VideoInfo: React.FC = () => {
    const videoState = useSelector((state: RootState) => state.video);
    const getVideoStateDisplay = () => videoState.isPlaying ? "Playing" : "Paused";

    return (
        <div className={`${styles.videoInfo} ${videoState.videoOpen ? styles.largeFont : styles.smallFont}`}>
        <p>Video Progress: {videoState.videoProgress.toFixed(0)}s</p>
        <p> {getVideoStateDisplay()}</p>
        <p>Duration: {videoState.videoDuration.toFixed(2)}s</p>
        <p>Offset: {videoState.videoOffsetInSeconds.toFixed(0)}s</p>
    </div>
    );
};

export default VideoInfo;
