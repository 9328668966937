import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { sanitizeTrackName } from 'utils/helpers';
import { setGenresFilter, setMoodsFilter, setInstrumentsFilter, setTitleFilter, setArtistFilter, resetFilters } from 'redux/slices';
import KeydownManager from '../../../services/KeydownManager';
import { useAppDispatch } from 'hooks';
import config from '../../../config.json';
import _ from 'lodash';
import highlightWords from 'highlight-words';  // Importing the highlight-words library

const StyledSearchField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: "rgba(var( --main-colors-white-rgb), 0.5)"
  },
  '& .MuiOutlinedInput-root': {
    height: "36px",
    padding: "6px 12px",
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: "rgba(var( --main-colors-white-rgb), 0.5)"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "rgba(var( --main-colors-white-rgb), 0.4)"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "rgba(var( --main-colors-white-rgb), 1)"
    },
  },
  '& .MuiInputLabel-outlined': {
    fontSize: '12px',
    transform: 'translate(14px, 10px) scale(1)',
    color: 'rgba(255, 255, 255, 0.5)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(1)',
      color: 'white'
    }
  }
}));

interface SearchFieldComponentProps {
  label: string;
  fullWidth?: boolean;
  InputProps?: any;
}

interface Suggestion {
  type: string;
  value: string;
  original?: string;
  id?: string;
}

const SearchFieldComponent: React.FC<SearchFieldComponentProps> = ({ label, fullWidth, InputProps }) => {
  const [input, setInput] = useState('');
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [open, setOpen] = useState(false); // To control the visibility of suggestions dropdown
  const [isFocused, setIsFocused] = useState(false);
  const [isSelectingSuggestion, setIsSelectingSuggestion] = useState(false);
  const [recentFilterType, setRecentFilterType] = useState<string | null>(null);
  const [recentFilterValue, setRecentFilterValue] = useState<string | null>(null);
  const { genres, moods, instruments } = useSelector((state: RootState) => state.filters);
  const API_ENDPOINT = config.monsterTracksCrudUrl;
  const keydownManager = KeydownManager.getInstance();
  const dispatch = useAppDispatch();

  const handleSuggestionClick = async (suggestion: Suggestion) => {
    const { value } = suggestion;
    setInput(value);
    setOpen(false);
    setIsSelectingSuggestion(true);
    await searchWithKeyword(suggestion);
    setIsSelectingSuggestion(false);
  };

  const formatPayload = (query: string) => {
    const payload: any = {
      operation: "autoComplete",
    };
    if (query) {
      payload.query = query;
    }
    return payload;
  };

  const fetchSuggestions = async (query: string) => {
    const payload = formatPayload(query);
    try {
      const response = await axios.post(API_ENDPOINT, payload);
      if (!response.data) {
        throw new Error('Response data is empty');
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      return [];
    }
  };

  const searchWithKeyword = async (suggestion: Suggestion) => {
    const { value, type, original, id } = suggestion;
    setRecentFilterType(type);
    setRecentFilterValue(original || value);

    console.log('searchWithKeyword', suggestion);

    switch (type) {
      case 'genre':
        dispatch(setGenresFilter([value]));
        break;
      case 'instrument':
        dispatch(setInstrumentsFilter([value]));
        break;
      case 'mood':
        dispatch(setMoodsFilter([value]));
        break;
      case 'title':
        if (id) {
          dispatch(resetFilters());
          navigate(`/track/${sanitizeTrackName(value)}/${id}`);
        } else {
          dispatch(setTitleFilter(original || value));
        }
        break;
      case 'artist':
        dispatch(setArtistFilter(original || value));
        break;
      default:
        break;
    }
  };

  const debouncedFetchSuggestions = useCallback(
    _.debounce(async (query: string) => {
      const filteredSuggestions = await fetchSuggestions(query);
      setOpen(true);
      setSuggestions(filteredSuggestions);
    }, 50),
    []
  );

  useEffect(() => {
    if (!isSelectingSuggestion) {
      if (input.length > 1) {
        debouncedFetchSuggestions(input);
      } else {
        setSuggestions([]);
        setOpen(false);
      }
    }
  }, [input, debouncedFetchSuggestions, isSelectingSuggestion]);

  const handleFocus = () => {
    setSuggestions([]);
    setInput('');
    setIsFocused(true);
    keydownManager.disableSpacebar();

    if (recentFilterType && recentFilterValue) {
      switch (recentFilterType) {
        case 'genres':
          dispatch(setGenresFilter(genres?.filter((genre) => genre !== recentFilterValue) ?? []));
          break;
        case 'instruments':
          dispatch(setInstrumentsFilter(instruments?.filter((instrument) => instrument !== recentFilterValue) ?? []));
          break;
        case 'moods':
          dispatch(setMoodsFilter(moods?.filter((mood) => mood !== recentFilterValue) ?? []));
          break;
        case 'artist':
          dispatch(setArtistFilter(undefined));
          break;
        default:
          break;
      }
      setRecentFilterType(null);
      setRecentFilterValue(null);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
      keydownManager.enableSpacebar();
    }, 200);
  };

  // Function to highlight the matched part of the text using highlight-words library
const highlightMatch = (text: string, searchString: string) => {
  const chunks = highlightWords({ text, query: searchString });

  return (
    <span>
      {chunks.map(({ text, match, key }) =>
        match ? (
          <span key={key} style={{ fontWeight: 'bold', color: 'rgba(var(--main-colors-white-rgb), 1)' }}>
            {text}
          </span>
        ) : (
          <span key={key} style={{ color: 'rgba(var(--main-colors-white-rgb), 0.8)' }}>
            {text}
          </span>
        )
      )}
    </span>
  );
};

  const renderSuggestions = () => {
    const nonTitleSuggestions = suggestions.filter(s => s.type !== 'title').slice(0, 4);
    const titleSuggestions = suggestions.filter(s => s.type === 'title');

    return (
      <>
        {nonTitleSuggestions.map((suggestion, index) => (
          <ListItem
            key={index}
            onClick={() => handleSuggestionClick(suggestion)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'var(--main-colors-black-4)',
              },
            }}
          >
            {highlightMatch(_.truncate(suggestion.original || '', { length: 40 }), input)}
          </ListItem>
        ))}
        {titleSuggestions.length > 0 && (
          <ListItem
            key="tracks-label"
            sx={{
              marginTop: '10px',
              cursor: 'default',
              backgroundColor: 'transparent',
              fontWeight: 'bold',
            }}
          >
            Tracks
          </ListItem>
        )}
        {titleSuggestions.map((suggestion, index) => (
          <ListItem
            key={`title-${index}`}
            onClick={() => handleSuggestionClick(suggestion)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'var(--main-colors-black-4)',
              },
            }}
          >
            {highlightMatch(_.truncate(suggestion.original || '', { length: 40 }), input)}
          </ListItem>
        ))}
      </>
    );
  };

  return (
    <div>
      <StyledSearchField
        variant="outlined"
        label={label}
        color="primary"
        size="small"
        fullWidth={fullWidth}
        data-lpignore="true"
        autoComplete="off"
        InputProps={{
          ...InputProps,
          onChange: (e) => setInput(e.target.value),
          onFocus: handleFocus,
          onBlur: handleBlur,
        }}
        value={input}
      />

      {suggestions.length > 0 && open && isFocused && (
        <List
          sx={{
            position: 'absolute',
            width: 'calc(400px)',
            marginLeft: '0px',
            backgroundColor: 'var(--main-colors-black-3)',
            color: 'white',
            zIndex: 9999,
          }}
        >
          {renderSuggestions()}
        </List>
      )}
    </div>
  );
};

export default SearchFieldComponent;
