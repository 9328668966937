import { createListenerMiddleware } from '@reduxjs/toolkit';
import { setGenresFilter, setMoodsFilter, setInstrumentsFilter, setBpmRangeFilter, setDurationRangeFilter, setTitleFilter, setArtistFilter } from './slices/filterSlice';
import { fetchFiltered } from './thunks/songListThunks';
import throttle from 'lodash.throttle';

const listenerMiddleware = createListenerMiddleware();

const throttledFetchSongs = throttle((listenerApi: any) => {
  listenerApi.dispatch(fetchFiltered());
}, 1000);


const handleFilterChange = async (action: any, listenerApi: any) => {

  try {
    // Dispatch the throttled fetch action with the current filters
    throttledFetchSongs(listenerApi);
  } catch (error) {
    console.error('Failed to fetch songs:', error);
  }
};

// List of filter actions to listen to
const filterActions = [
  setGenresFilter,
  setMoodsFilter,
  setInstrumentsFilter,
  setBpmRangeFilter,
  setDurationRangeFilter,
  setTitleFilter,
  setArtistFilter
];
// Start listening to each filter action
filterActions.forEach(actionCreator => {
  listenerMiddleware.startListening({
    actionCreator,
    effect: (action, listenerApi) => {
      //filter actions here
      handleFilterChange(action, listenerApi);
    }
  });
});


export default listenerMiddleware.middleware; 
