// favoritesService.ts
import axios from 'axios';
import config from '../config.json';
import favoritesData from '../fixtures/favoritesFixture.json';
import favoritesSongsData from '../fixtures/favoriteSongsFixture.json';

const API_BASE_URL = config.favoritesUrl;

interface Favorite {
  userId: string;
  trackId: string;
}

// Add a track to favorites
export const addFavorite = async (favorite:Favorite): Promise<void> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/favorites/add`, { favorite });
    console.log('Add favorite response:', response.data);
  } catch (error) {
    console.error('Error adding favorite:', error);
    throw error;
  }
};

// Remove a track from favorites
export const removeFavorite = async (favorite:Favorite): Promise<void> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/favorites/remove`, { favorite});
    console.log('Remove favorite response:', response.data);
  } catch (error) {
    console.error('Error removing favorite:', error);
    throw error;
  }
};

// Fetch all favorite tracks for a user
export const getFavorites = async (userId: string): Promise<string[]> => { // Assuming the response will be an array of track IDs
  try {
    const response = await axios.get(`${API_BASE_URL}/favorites`, { params: { userId } });
    console.log('Get favorites response:', response.data);
    return response.data; // Adjust according to the actual API response structure
  } catch (error) {
    console.error('Error fetching favorites:', error);
    throw error;
  }
};


export const getMockFavorites = async (userId: string): Promise<Favorite[]> => {
  try {
    await new Promise(resolve => setTimeout(resolve, 100)); 
    const userFavorites = favoritesData.Items || [];
    console.log('Get favorites response:', userFavorites);
    return userFavorites;
  } catch (error) {
    console.error('Error fetching favorites:', error);
    throw error;
  }
};

export const fetchTracksDetailsByIds = async (trackIds:string[]) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/tracks/details`, { ids: trackIds });
    return response.data; 
  } catch (error) {
    console.error('Error fetching favorites:', error);
  }
};

export const fetchMockTracksDetailsByIds = async (trackIds: string[]) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const mockData = favoritesSongsData;
  const filteredData = mockData.filter(track => trackIds.includes(track.id));

  return filteredData;
};
