import React, { useState, useEffect, useRef } from 'react';
// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
// import '../../styles/tippy.css';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { useSelector } from 'react-redux';
// import { RootState } from 'src/redux/store';
import { useAppDispatch } from 'hooks';
import { selectVideoDuration } from 'redux/selectors';
import { setVideoOffsetInSeconds } from 'redux/slices';
import { VideoTimelineDraggerProps } from 'src/types';
import styles from './VideoTimelineDragger.module.css';

// Todo: Finish adding the tooltip to show when the video rectangle is out of range
const VideoTimelineDragger: React.FC<VideoTimelineDraggerProps> = ({ id, width, audioDuration, handleSeekTrackTo }) => {
    const dispatch = useAppDispatch()
    const [dragDelta, setDragDelta] = useState<number>(0);
    const [justFinishedDrag, setJustFinishedDrag] = useState<boolean>(false);
    const [isVideoDragging, setIsVideoDragging] = useState<boolean>(false);
    const draggableRef = useRef(null);
    // const selectedVideoDuration = useSelector(selectVideoDuration);
    // const videoInRange = useSelector((state: RootState) => state.video.videoInRange)
    // const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 20 }); // Example Y offset
    // Handle the logic to update the video rectangle width based on video duration and other factors
    // useEffect(() => {
    // }, [selectedVideoDuration]);


       // Reset video offset when id changes
       useEffect(() => {
        setDragDelta(0);
        setJustFinishedDrag(false);
        setIsVideoDragging(false);
        dispatch(setVideoOffsetInSeconds(0));
    }, [id, dispatch]);
 

    const handleDrag: DraggableEventHandler = (e, data) => {
        //how far in pixels the video rectangle has been dragged
        setDragDelta(data.x);
        setJustFinishedDrag(false);
        // setTooltipPosition({ x: data.x + width/2, y: 20 });
    };

    const handleDragStop: DraggableEventHandler = () => {
        setIsVideoDragging(false);
        setJustFinishedDrag(true);
        setTimeout(() => {
            setJustFinishedDrag(false);
        }, 100);

        const audioEditorContainer = document.querySelector('#audio-editor') as HTMLDivElement;
        const videoOffsetInSeconds = dragDelta / audioEditorContainer.clientWidth * audioDuration;

        dispatch(setVideoOffsetInSeconds(videoOffsetInSeconds));
    };

    const handleTimelineClick = (e: React.MouseEvent<HTMLElement>) => {

        if (isVideoDragging || justFinishedDrag) {
            return;
        }
        handleSeekTrackTo(e.clientX);
    };

    const handleDragHandleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };


    return (
        <>
            <Draggable
                handle=".drag-handle"
                axis="x"
                bounds={{ left: -width, right: 450, top: 0, bottom: 0 }}
                onStart={() => setIsVideoDragging(true)}
                onStop={handleDragStop}
                onDrag={handleDrag}
                position={{ x: dragDelta, y: 0 }}
                nodeRef={draggableRef}
            >

                <div id="video-timeline" onClick={handleTimelineClick}
                    className={styles.videoRectangle}
                    style={{ width: `${width}px` }}
                >

                    <div
                        ref={draggableRef}
                        className={`drag-handle ${styles.dragHandle}`} // CSS Module class
                        style={{ height: '3px', width: `${width * 0.98}px` }} // Inline style for dynamic width
                        onClick={handleDragHandleClick}>
                        
                    </div>

                </div>

            </Draggable>

            {/* <Tippy content="Keep the rectangle within audio position"
                visible={!videoInRange} 
                placement="top"
                theme="customTippyThemeWarning"
                popperOptions={{
                    strategy: 'fixed',
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [tooltipPosition.x, tooltipPosition.y],
                            },
                        },
                    ],
                }}>
                <div style={{ position: 'absolute', top: 0, left: 0, width: 0, height: 0 }}></div>
            </Tippy> */}
        </>
    );
};

export default VideoTimelineDragger;
