import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'src/redux/store';
import { isTrackFavorited, addFavorite, removeFavorite } from 'redux/slices/favoritesSlice';
import { FavoriteBorder, Favorite } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { SongProps } from 'types/types';
import styles from './IconButton.module.css'; 
import CustomTooltip from './TooltipComponent';
 
interface FavoriteButtonProps {
    songProps: SongProps;
    hideTooltip?: boolean;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({ songProps, hideTooltip }) => {
    const dispatch = useDispatch<AppDispatch>();
    const isFavorite = useSelector((state: RootState) => isTrackFavorited(state, songProps.id));

    const handleToggleFavorite = () => {
        if (isFavorite) {
              dispatch(removeFavorite(songProps.id));
        } else {
              dispatch(addFavorite(songProps));  
        }
    };

    const iconButton = (
        <IconButton
            aria-label="favorite"
            className={`${styles.iconButton} ${styles.iconButtonHover}`}
            onClick={handleToggleFavorite}
        >
            {isFavorite ? <Favorite className={styles.icon} /> : <FavoriteBorder className={styles.icon} />}
        </IconButton>
    );


    return hideTooltip ? (
        iconButton
    ) : (
        <CustomTooltip text="Favorites">
            {iconButton}
        </CustomTooltip>
    );
};

export default FavoriteButton;
