import React from 'react';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import './index.css';
import './colors.css';
import App from './App';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import KeydownManager from './services/KeydownManager';
import Hotjar from './components/Hotjar'; // Import the Hotjar component 

// Initialize the KeydownManager
KeydownManager.getInstance(); // Ensure this is after store is created

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Hotjar />
      <App />
    </PersistGate>
  </Provider>
);
