import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'src/redux/store';
import { SelectChangeEvent } from '@mui/material/Select';
import { MenuProps, StyledFormControl, StyledMenuItem, StyledCheckbox, StyledOutlinedInput, StyledInputLabel } from '../../../components/shared/StyledMultipleSelect';
import _ from 'lodash';

import {
  Typography,
  ListItemText,
  Select,
} from '@mui/material';

interface MultipleSelectCheckmarksProps {
  names: string[];
  labelName: string;
  onChange: (selectedValues: string[]) => void;
  filterKey: 'genres' | 'moods' | 'instruments';
}

const MultipleSelectCheckmarks: React.FC<MultipleSelectCheckmarksProps> = ({ names, labelName, onChange, filterKey }) => {
  const selectedItems = useSelector((state: RootState) => state.filters[filterKey], shallowEqual);
  const [label, setLabel] = useState<string>(labelName);

  const handleLabel = useCallback((count: number) => {
    const key = count === 1 ? filterKey.slice(0, -1) : filterKey;
    setLabel(count === 0 ? labelName : `${count} ${key} selected`);
  }, [filterKey, labelName]);

  const handleChange = useCallback((event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    handleLabel(selectedValues.length);
    onChange(selectedValues);
  }, [handleLabel, onChange]);

  useEffect(() => {
    handleLabel(selectedItems?.length ?? 0);
    console.log('selectedItems:', selectedItems);
  }, [selectedItems, handleLabel]);

  const renderValue = useCallback((selected: string[]) => {
    return selected.length === 0 ? labelName : `${selected.length} ${selected.length === 1 ? filterKey.slice(0, -1) : filterKey} selected`;
  }, [filterKey, labelName]);

  return (
    <StyledFormControl variant="outlined" fullWidth>
      <StyledInputLabel id="multiple-checkbox-label" shrink={false}>
        {label}
      </StyledInputLabel>
      <Select
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={selectedItems}
        onChange={handleChange}
        renderValue={renderValue}
        MenuProps={MenuProps}
        displayEmpty
        label={labelName}
        input={<StyledOutlinedInput notched={selectedItems?.length ?? 0  ? true : undefined} />}
        inputProps={{ 'aria-label': label }}
      >
        {names.map((name) => (
          <StyledMenuItem key={name} value={name}>
            <StyledCheckbox checked={selectedItems && (selectedItems.indexOf(name))  > -1} />
            <ListItemText primary={<Typography sx={{ fontSize: 12 }}>{_.startCase(name)}</Typography>} />
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default MultipleSelectCheckmarks;
