import { SongProps, SongPropsNew } from 'types/types';
import config from '../config.js';
 
/**
 * Maps an array of `SongPropsNew` to an array of `SongProps`.
 * @param songsNew An array of `SongPropsNew` objects.
 * @returns An array of `SongProps` objects.
 */

export function mapSongs(songsNew: SongPropsNew[]): SongProps[] {
  const mappedSongs: SongProps[] = [];
  // console.log('songsNew ',songsNew);
  songsNew.forEach((songNew) => {
    //Todo: improve design here to avoid passing items that are already mapped (favorites, similar track item...)
    //Get rid of the SongPropsNew type and use only SongProps
    let duration = songNew.trackDurationSecs || 0;
    let audioPath = `artists/${songNew.artistDetails.artistId}/audio/mp3/${songNew.id}.mp3`;
    let audioUrl = `${config.cdnBaseUrl}/${audioPath}`;
    const artistName = songNew.artistDetails.artistName;
    const coverSleevePath = `${config.cdnBaseUrl}/artists/${songNew.artistId}/images/`;
    const coverSleeveUrl = (songNew.albumDetails)?`${coverSleevePath}${songNew.albumDetails?.albumId}.cover.png`:`${coverSleevePath}default.cover.png`;//$songNew.coverSleeveUrl;
    let peaksUrl = (songNew.peaks)?`${config.cdnBaseUrl}/${songNew.peaks}`:undefined;
    //console.log('songNew ',audioPath, audioUrl, artistName, coverSleeveUrl);
    const mappedSong: SongProps = {
      id: songNew.id,
      album: songNew.album,
      artist: artistName,
      artistId: songNew.artistId,
      audioPath: audioPath,
      audioUrl: audioUrl,
      bpm: songNew.bpm, 
      coverSleeveUrl: coverSleeveUrl, 
      dateCreated: songNew.dateCreated,
      duration: duration,
      filename: songNew.filename,
      genres: songNew.genres,  
      instruments: songNew.instruments,  
      instrumentation: songNew.instrumentation,  
      moods: songNew.moods, 
      scale: songNew.keyFlat || songNew.scale, 
      title: songNew.title,
      albumDetails: songNew.albumDetails,
      artistDetails: songNew.artistDetails,
      peaksUrl: peaksUrl || undefined,

    };
    mappedSongs.push(mappedSong);
  }
  );

  return mappedSongs;
}
