// PlayPauseButton.tsx
import React from 'react';
import { PlayArrow, Pause } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

const PlayArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface PlayPauseButtonProps {
  isPlaying: boolean;
  handlePlayPause: () => void;
}

const PlayPauseButton: React.FC<PlayPauseButtonProps> = ({ isPlaying, handlePlayPause }) => (
  <PlayArrowWrapper>
    <IconButton
      aria-label={isPlaying ? 'pause' : 'play'}
      onClick={handlePlayPause}
      sx={{
        width: '28px',
        height: '28px',
        padding: '6px',
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },
      }}
    >
      {isPlaying ? <Pause sx={{ fontSize: "20px" }} /> : <PlayArrow sx={{ fontSize: "20px" }} />}
    </IconButton>
  </PlayArrowWrapper>
);

export default PlayPauseButton;
