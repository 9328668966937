// UploadInstructions.tsx
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

type UploadInstructionsProps = {
  open: boolean;
  onClose: () => void;
};

const UploadInstructionsDialog: React.FC<UploadInstructionsProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'rgba(var( --main-colors-black-2-rgb),1)',
          borderRadius: '10px',
          padding: '10px',
          color: 'rgba(var(--main-colors-white-rgb), 0.9)',
          border: '1px solid rgba(var(--main-colors-black-4-rgb),0)',
          position: 'relative'
        }
      }}
    >
      <Button
        onClick={onClose}
        sx={{
          color: 'rgba(var(--main-colors-white-rgb),1)',
          position: 'absolute',
          top: 8,
          right: 0,
        }}
      >
        <CloseIcon />
      </Button>

      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Streamline Your Soundtrack Search
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'rgba(var(--main-colors-white-rgb), 0.7)' }} component={"div"}>
          <p style={{ color: 'rgba(var(--main-colors-white-rgb), 0.7)', 'fontSize': '15px', marginTop: '0px' }}>
            Start by uploading your audio or video reference. Discover tailored tracks. Sync seamlessly with your video. Download and elevate your project effortlessly. Unlock the power of perfect music matching with us.
          </p>
        </DialogContentText>
      </DialogContent>

    </Dialog>
  );
};

export default UploadInstructionsDialog;
