// selectors.ts
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import { SongProps } from 'types/types';

export const selectSelectedSongId = (state: RootState) => state.songList.selectedSong?.id;
const selectVideoState = (state: RootState) => state.video;


export const selectSelectedSongDuration = createSelector(
  [selectSelectedSongId, (state: RootState) => state.audio.waveSurferInstances],
  (selectedSongId, waveSurferInstances) => {
    return selectedSongId ? waveSurferInstances[selectedSongId]?.duration : undefined;
  }
);

export const selectVideoDuration = createSelector(
  [selectVideoState],
  (videoState) => videoState.videoDuration
);


export const selectSongPropById = <T extends keyof SongProps>(
  songId: string,
  prop: T
) => (state: RootState): SongProps[T] | undefined => {
  const song = state.songList.songs.find(song => song.id === songId);
  return song ? song[prop] : undefined;
};


// User selectors
const selectUserState = (state: RootState) => state.user;
const selectUserProfile = (state: RootState) => state.user.profile;
const selectUserIsAuth = (state: RootState) => state.user.isAuth;
const selectUserLoading = (state: RootState) => state.user.loading;

// Memoized selectors
export const getUserProfile = createSelector(selectUserProfile, (profile) => profile);

export const getIsAuth = createSelector(selectUserIsAuth, (isAuth) => isAuth);

export const getUserLoading = createSelector(selectUserLoading, (loading) => loading);

export const getUserError = createSelector(selectUserState, (userState) => userState.error);
