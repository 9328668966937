import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WaveSurferInstance } from 'src/types/';
import { current } from 'immer'; // Import current from immer

const defaultWaveSurferInstance = {
  playPauseState: false,
  duration: 0,
  currentTime: 0,
  seek: 0,
  updateTrigger: 0,
  visible:true, 
  volume:20
};
interface AudioState {
  playingTrackId: string | null;
  lastUpdatedBy: 'audio' | 'video' | null;
  volumeLevel: number,
  waveSurferInstances: { [instanceId: string]: WaveSurferInstance };
}

const initialAudioState: AudioState = {
  playingTrackId: null,
  lastUpdatedBy: null,
  volumeLevel: 20,
  waveSurferInstances: {},
};

const audioSlice = createSlice({
  name: 'audio',
  initialState: initialAudioState,
  reducers: {
    playTrack: (state, action: PayloadAction<string>) => {
      // console.log('audio slice playTrack audio ');
      const id = action.payload;
      state.playingTrackId = id;
      state.lastUpdatedBy = 'audio';
      if (state.waveSurferInstances[id]) {
        state.waveSurferInstances[id].playPauseState = true;
        state.waveSurferInstances[id].volume = state.volumeLevel; //match the instance volume to the global value
      }
    },
    stopTrack: (state) => {
      // console.log('stopTrack audio slice state.playingTrackId ', current(state).playingTrackId);
      if (state.playingTrackId && state.waveSurferInstances[state.playingTrackId]) {
        state.waveSurferInstances[state.playingTrackId].playPauseState = false;
      }
      state.playingTrackId = null;
    },
 
    setVolume: (state, action: PayloadAction<{ volumeLevel: number }>) => {
      const { volumeLevel } = action.payload;
      state.volumeLevel = volumeLevel;
      if (state.playingTrackId && state.waveSurferInstances[state.playingTrackId]) {
        state.waveSurferInstances[state.playingTrackId].volume = volumeLevel;
      }
    },
 

    seekTrackTo: (state, action: PayloadAction<{ instanceId: string; seek: number }>) => {
      const { instanceId, seek } = action.payload; 
      if (state.waveSurferInstances[instanceId]) {
        state.waveSurferInstances[instanceId].seek = seek;
        //this will force the seek state to update in the component
        //also when value hasn't changed - alternative solution?
        state.waveSurferInstances[instanceId].updateTrigger += 1;
      }
    },

    // seekToLastPosition: (state, action: PayloadAction<{ instanceId: string }>) => {
    //   const { instanceId } = action.payload; 
    //   if (state.waveSurferInstances[instanceId]) {
    //     //this will force the seek state to update in the component
    //     //also when value hasn't changed - alternative solution?
    //     state.waveSurferInstances[instanceId].updateTrigger += 1;
    //   }
    // },

    updateWaveSurferInstance: (state: AudioState, action: {
      payload: {
        instanceId: string;
        changes: Partial<WaveSurferInstance>;
      };
      type: string;
    }) => {
      const { instanceId, changes } = action.payload;
      const instance = state.waveSurferInstances[instanceId];
      if (instance) {
        state.waveSurferInstances[instanceId] = { ...instance, ...changes };
      }
    },
    createWaveSurferInstance: (state, action: PayloadAction<string>) => {
      const instanceId = action.payload;
      // console.log('Create WS state ', instanceId);
       state.waveSurferInstances[instanceId] = { ...defaultWaveSurferInstance };
    },
    destroyWaveSurferInstance: (state, action: PayloadAction<string>) => {
      const instanceId = action.payload;
      delete state.waveSurferInstances[instanceId];
      // console.log('Destroy WS state ', instanceId);
      // console.log(Object.keys(state.waveSurferInstances));
      // console.log('Total WS STATE instances  ', Object.keys(state.waveSurferInstances).length);
    }
  },

});

export const {
  playTrack,
  stopTrack,
  seekTrackTo,
  // seekToLastPosition,
  updateWaveSurferInstance,
  createWaveSurferInstance,
  destroyWaveSurferInstance,
  setVolume,
} = audioSlice.actions;

export default audioSlice.reducer;