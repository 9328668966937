import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

interface TooltipComponentProps {
    text: string;
    children: React.ReactElement;
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({ text, children }) => {
    return (
        <Tooltip
            title={<Typography sx={{ fontSize: '12px',fontWeight:'bold' }}>{text}</Typography>}
            placement="top"
            // arrow
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -17], // Adjust the offset here to move the tooltip closer
                        },
                    },
                ],
            }}
    >
            {children}
        </Tooltip>
    );
};

export default TooltipComponent;
