import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, CircularProgress, Typography, Box, DialogContent } from '@mui/material';
import { RootState, AppDispatch } from 'src/redux/store';
import { resetDownload } from 'redux/slices/';
interface DownloadState {
  isLoading: boolean;
  progress: number;
  downloadLink: string | null;
  error: string | null;
}

const DownloadPopup: React.FC = () => {
  const { isLoading, progress, downloadLink } = useSelector((state: RootState) => state.download as DownloadState);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    if (isLoading && !downloadLink) {
      setOpen(true);
    }
  }, [isLoading, downloadLink]);

  const handleClose = () => {
    setOpen(false);
    dispatch(resetDownload());
  };


  const getProgressText = () => {
    if (progress >= 0 && progress < 100) {
      return <span>Preparing your video</span>;
    } else {
      return (
        <>
          <span>Your video is ready!</span>
          <br />
          <span style={{ fontWeight:'lighter', fontSize: '11px' }}>Download will automatically start.</span>
        </>
      );
    }
  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="download-popup"
      PaperProps={{
        sx: {
          position: 'fixed',
          top: '200px',
          right: 0,
          maxHeight: '100%',
          overflowY: 'auto',
          width: '300px', // Assuming the base width is 350px, so twice would be 700px
          height: '300px', // Assuming the base height is 250px, so twice would be 500px
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(25,25, 25, 0.8)',
          color: 'white',
        }
      }}
    >
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>

        <Box sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(var(--main-colors-cyan-light-2-rgb),0.37)',
          borderRadius: '50%',
          width: '100px',
          height: '100px',
        }}>
          <CircularProgress
            variant="determinate"
            value={progress}
            size={80}
            thickness={6}
            sx={{
              color: 'rgba(var(--main-colors-cyan-light-2-rgb),0.37)',
              position: 'absolute',
            }}
          />
          <Typography variant="caption" component="div" sx={{
            color: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '1rem',
          }}>
            {`${progress}%`}
          </Typography>

        </Box>
        <Typography variant="h6" sx={{ fontSize: '1rem', color: 'rgba(255,255, 255, 0.7)', textAlign: 'center', padding: '15px' }}>
          {getProgressText()}
        </Typography>
      </DialogContent>
    </Dialog>

  );
};

export default DownloadPopup;
