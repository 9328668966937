import React, { useEffect, useRef, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getUserProfile } from '../../../redux/selectors';
import { SongListProps } from 'src/types';
import styled from 'styled-components';
import styles from './SongList.module.css';
import SongComponent from './SongComponent';
import SongComponentAdmin from './SongComponentAdmin';
import { useLocation } from 'react-router-dom';

const SongContainer = styled.div<{ $isSelected: boolean }>`
  margin-bottom: 2px;
  display: flex;
  height: 100px;
  background-color: ${({ $isSelected }) => $isSelected ? 'rgba(158, 158, 158, 0.2)' : 'transparent'};
  &:hover {
    background-color:rgba(158, 158, 158, 0.2);
  }
`;

const MemoizedSongContainer = memo(SongContainer);

const SongList: React.FC<SongListProps> = ({ songs, autoScrollList, fetchMoreSongs, loading }) => {
  // console.log('SongList rendered ');
  const selectedSongId = useSelector((state: RootState) => state.songList.selectedSongId);
  const [visibleSongs, setVisibleSongs] = useState<Set<string>>(new Set());
  const location = useLocation();
  const isLibrary = location.pathname.includes('/search/library');
  const songRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const scrollContainerRef = useRef<HTMLDivElement | null>(null); 
  const profile = useSelector(getUserProfile);
  const { role, userId } = profile || { role: '', userId: '' };
  const isAdmin = role === 'admin' && userId === '264f23bc-d751-466f-a249-863b808baaf5';
  const observer = useRef<IntersectionObserver | null>(null);
  const paginationLimit = 25;

 

  // Handle visibility changes
  const handleVisibilityChange = useCallback((targetId: string, isVisible: boolean) => {
    setVisibleSongs((prevVisibleSongs: Set<string>) => {
      const newSet = new Set(prevVisibleSongs);
      if (isVisible) {
        newSet.add(targetId);
        // console.log('targetId,selectedSongId:', targetId,selectedSongId);
      } else if(targetId !== selectedSongId) {
         newSet.delete(targetId);
      }
      return newSet;
    });
  }, [selectedSongId]);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const target = entry.target as HTMLElement;
        if (target.dataset.id) {
          const index = songs.findIndex(song => song.id === target.dataset.id);
          if (index !== -1) {
            handleVisibilityChange(target.dataset.id, entry.isIntersecting);
          }
        }
        if (entry.isIntersecting && !entry.target.nextElementSibling && fetchMoreSongs && songs.length >= paginationLimit) {
          console.log('Fetching more songs...');
          fetchMoreSongs();
        }
      });
    }, {
      threshold: 0,
      rootMargin: '20px',
    });
    Object.values(songRefs.current).forEach((element) => {
      if (element instanceof Element) {
        observer.current!.observe(element);
      }
    });
    return () => {
      if (observer.current) {
        Object.values(songRefs.current).forEach((element) => {
          if (element instanceof Element) {
            observer.current!.unobserve(element);
          }
        });
        observer.current.disconnect();
      }
    };
  }, [songs, handleVisibilityChange, fetchMoreSongs]);

  //scroll into view...
  useEffect(() => {
    if (selectedSongId && songRefs.current[selectedSongId] && autoScrollList) {
      const visibleSongsArray = Array.from(visibleSongs);
      if (selectedSongId === visibleSongsArray[1] || selectedSongId === visibleSongsArray[visibleSongsArray.length - 2]) {
        songRefs.current[selectedSongId]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedSongId, visibleSongs, autoScrollList]);


  useEffect(() => {
    if (scrollContainerRef.current) {
      // Reset scroll position to 0
      scrollContainerRef.current.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, []);

 


  return (
<div className={styles.songListWrapper} ref={scrollContainerRef}>
      {songs.map((song, index) => (
        <MemoizedSongContainer
          ref={el => {
            if (el) songRefs.current[song.id] = el;
          }}
          data-id={song.id}
          key={song.id}
          $isSelected={selectedSongId === song.id} 
        >
          {visibleSongs.has(song.id) && (
            isAdmin && isLibrary ? (
              // <SongComponentAdmin {...song} index={index} />
              <></>
            ) : (
              <SongComponent {...song} index={index} />
            )
          )}
        </MemoizedSongContainer>
      ))}
    </div>
  );
};

export default memo(SongList);
