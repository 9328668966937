// src/services/filterService.ts
import axios from 'axios';
import config from '../config.json';

const API_ENDPOINT_MONSTER = config.monsterTracksCrudUrl;
const API_ENDPOINT_MUSIIO = config.musiioTracksCrudUrl;  

/**
 * Deletes tracks with the specified IDs from MonsterMusicIoTracks table.
 * @param {string[]} ids - The IDs of the tracks to delete.
 * @returns {Promise<string[]>} The IDs of the deleted tracks.
 * @throws {Error} If the API call fails.
 */
export const deleteFromTracksTbl = async (ids: string[]): Promise<string[]> => {
    const requestBody = {
        operation: 'delete',
        ids
    };

    try {
        const response = await axios.post(API_ENDPOINT_MONSTER, requestBody, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data.deletedIds;
    } catch (error) {
        console.error('Error deleting tracks:', error);
        throw error;
    }
};

interface DeleteRequestParams {
    action: string;
    ids: string[];
}

/**
 * Deletes tracks from the Musiio API with the specified IDs.
 * Note: this is not a direct request to Musiio API but via a Lambda function.
 * @param {string[]} ids - The IDs of the tracks to delete.
 * @returns {Promise<void>}
 * @throws {Error} If the API call fails.
 */
export const deleteTracksFromMusiio = async (ids: string[]): Promise<void> => {
    const params: DeleteRequestParams = {
        action: 'delete',
        ids: ids,
    };
    try {
        const response = await axios.post(API_ENDPOINT_MUSIIO, params);
        return (response.data.deletedIds);
    } catch (error) {
        console.error('Error deleting tracks:', error);
    }
};

interface GetRequestParams {
    operation: string;
    id: string;
}

export const getTrackById = async (id: string): Promise<any> => {
    const params: GetRequestParams = {
        operation: 'getById',
        id,
    };
    try {
        const response = await axios.post(API_ENDPOINT_MONSTER, params);
        return response.data;
    } catch (error) {
        console.error('Error getting track', error);
        throw new Error('Failed to get track'); // Throw an error to ensure it's clear when something goes wrong.
    }
};



interface UpdateData {
    id: string; // 'id' is required
    [key: string]: any; // This allows any number of additional attributes
}

export const updateTrackTbl = async (data: UpdateData): Promise<void> => {

    try {
        const { id, ...attributes } = data;
        try {
            const payload = {
                operation: "update",
                id: id,
                updateData: attributes
            };

            const response = await axios.post(API_ENDPOINT_MONSTER, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log('Update successful', response.data);
        } catch (error) {
            console.error('Failed to update analysis results:', error);
            throw new Error('Failed to update analysis results');
        }
    } catch (error) {
        console.error('Failed to update track:', error);
        throw new Error('Failed to update track');
    }
};