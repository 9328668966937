import { AppThunk } from '../store';
import { RootState } from '../store';
import {
  playTrack,
  stopTrack,
  pauseVideo,
  playVideo,
  setSelectedSongById,
  saveSelectedTrack,
  setSelectedSong
} from '../slices';

 
export const manageOriginalTrackPlaybackThunks = (
  songId: string | null,
  play?: boolean,
): AppThunk => 
  (dispatch, getState: () => RootState) => {
    const { trackReference } = getState();
    const { audio } = getState();

    if(!songId) return;

    if (play) {//play the audio and video
      if (audio.playingTrackId) {
        dispatch(stopTrack()); //set playPause to false and playingTrackId to null
      }

        if(trackReference.playTrackReference ){
          dispatch(saveSelectedTrack(songId)); //will store it in track reference for retrieval
          songId = trackReference.trackReferenceId; //assign the original-track id
          dispatch(setSelectedSong({ id: songId, song: trackReference.song}));
          dispatch(playTrack(songId));
        }else{ //toggle back to selected track
          songId = trackReference.selectedTrack; //retrieve selected track
          dispatch(saveSelectedTrack(null)); //reset selected track in reference
          dispatch(setSelectedSongById(songId)); //set the selected track
          if(songId)dispatch(playTrack(songId));
        }
      dispatch(playVideo());
    } else {
      dispatch(stopTrack());
      dispatch(pauseVideo());
    }
  };
