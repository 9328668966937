// src/services/filterService.ts
import axios from 'axios';
import config from '../config.json';
import { mapSongs } from '../utils/songMapping';
import { FilterParams } from 'src/types';

const API_ENDPOINT = config.monsterTracksCrudUrl;

// Flag to track if a request is in progress
let isRequestInProgress = false;

/**
 * Formats the filter parameters and the lastEvaluatedKey into a payload that can be sent to the API.
 * 
 * @param {FilterParams} params - The filter parameters used to refine the song search (e.g., BPM range, genres, moods).
 * @param {Record<string, any>} [lastEvaluatedKey] - The key used for pagination to fetch the next set of results.
 * @returns {any} - The formatted payload object to be sent in the API request.
 */
const formatPayload = (params: FilterParams, lastEvaluatedKey?: Record<string, any>,pageLimit?:Number) => {
  
  const payload: any = {
    operation: "filter",
  };

  // Include BPM range filter if provided
  if (params.bpmRange) {
    payload.bpmRange = { min: params.bpmRange[0], max: params.bpmRange[1] };
  }

  // Include duration range filter if provided
  if (params.durationRange) {
    payload.durationRange = { min: params.durationRange[0], max: params.durationRange[1] };
  }

  // Include genres filter if provided, converting all genres to lowercase
  if (params.genres) {
    payload.genres = params.genres.map(genre => genre.toLowerCase());
  }

  // Include moods filter if provided, converting all moods to lowercase
  if (params.moods) {
    payload.moods = params.moods.map(mood => mood.toLowerCase());
  }

  // Include instruments filter if provided, converting all instruments to lowercase
  if (params.instruments) {
    payload.instruments = params.instruments.map(instrument => instrument.toLowerCase());
  }

  // Include title filter if provided
  if (params.title) {
    payload.title = params.title;
  }

  // Include artist filter if provided
  if (params.artist) {
    payload.artist = params.artist;
  }

  // Include the lastEvaluatedKey for pagination if provided
  if (lastEvaluatedKey) {
    payload.lastEvaluatedKey = lastEvaluatedKey;
  }

  if (pageLimit) {
    payload.limit = pageLimit;
  }

  return payload;
};

/**
 * Fetches filtered songs from the API based on the provided filter parameters.
 * 
 * @param {FilterParams} filters - The filter parameters used to refine the song search (e.g., BPM range, genres, moods).
 * @param {Record<string, any>} [lastEvaluatedKey] - The key used for pagination to fetch the next set of results.
 * @returns {Promise<{ data: any[], lastEvaluatedKey?: Record<string, any> }>} - A promise that resolves to an object containing the filtered songs and the new lastEvaluatedKey for pagination.
 * @throws {Error} - Throws an error if the API request fails or if the response data is empty.
 */
export const fetchFilteredSongs = async (filters: FilterParams, lastEvaluatedKey?: Record<string, any>, pageLimit?:Number) => {
  
  if (isRequestInProgress) {
    console.log('Request is already in progress. Skipping new request.');
    return;  // Skip if another request is in progress
  }

  // Set the flag to indicate a request is in progress
  isRequestInProgress = true;
  
  // Format the payload based on the filters and pagination key
  const payload = formatPayload(filters, lastEvaluatedKey, pageLimit);
  // console.log('fetchFilteredSongs payload', payload);

  try {
    // const requestId = new Date().toISOString();
    // console.log(`Request ${requestId} before request only logs once`);
    // Send a POST request to the API endpoint with the formatted payload
    const response = await axios.post(API_ENDPOINT, payload);
    // console.log(`Request ${requestId} after request logs response`, response);
    
    // Check if the response data is empty and throw an error if it is
    if (!response.data) {
      throw new Error('Response data is empty');
    }
    // console.log('Results filter songs ', response.data);
    // Extract items and the new lastEvaluatedKey from the response data
    const { items, lastEvaluatedKey: newLastEvaluatedKey } = response.data;

    // Map the items using the mapSongs utility function or return an empty array if no items are found
    const data = items.length > 0 ? mapSongs(items) : [];

    console.log('mapped items', JSON.stringify(data.length));

    
    // Return the mapped data and the new lastEvaluatedKey for pagination
    return { data, lastEvaluatedKey: newLastEvaluatedKey };

  } catch (error) {
    console.error('Error fetching filtered songs:', error);
    throw new Error('Failed to fetch filtered songs');
  } finally {
    // Reset the flag after the request completes
    isRequestInProgress = false;
  }
};
