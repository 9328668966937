import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from '../components/UploadDialog.module.css';
import UploadArea from '../components/UploadArea';
import UploadProgress from '../components/UploadProgress';
import ContinueButton from '../components/ContinueButton';
import CloseButton from '../components/CloseButton';
import CustomSwitch from '../../../components/shared/CustomSwitch';
import { useDropzone } from 'react-dropzone';
import { uploadFileToS3 } from '../../../services/s3UploadService';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'src/redux/store';
import { RootState } from 'redux/store';
import { resetUpload } from 'redux/slices';
import { getUserProfile } from 'redux/selectors';
import { resetDialogs } from 'redux/slices/dialogSlice';
import { findSimilarTracks, setAutoLoad } from 'redux/slices/trackReferenceSlice';
import { setUploadProgress, setUploadComplete, startUpload, resetTrackReference, updateTrackReferenceUrl, setVideoAvailable } from 'redux/slices/';

type UploadStateTypes = "select" | "uploading" | "complete" | "error";
type MediaType = "AUDIO" | "VIDEO" | "";

const UploadDialogContainer: React.FC = () => {
  const [uploadState, setUploadState] = useState<UploadStateTypes>("select");
  const progress = useSelector((state: RootState) => state.upload.progress);
  const uploadedFile = useSelector((state: RootState) => state.upload.uploadedFile);
  const profile = useSelector(getUserProfile);
  const clientId = profile?.email;
  const deeplink = useSelector((state: RootState) => state.dialog.deeplink);
  const trackReferenceUrl = useSelector((state: RootState) => state.trackReference.url);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [cancelUpload, setCancelUpload] = useState<() => void>();
  const [uploadMediaType, setUploadMediaType] = useState<MediaType>('');
  const [similaritySelected, setSimilaritySelected] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleUploadStart = useCallback(async (file: File) => {
    dispatch(startUpload());
    dispatch(resetTrackReference());
    dispatch(setVideoAvailable(false));

    setUploadMediaType(`${file.type.startsWith('audio/') ? 'AUDIO' : 'VIDEO'}`);
    setUploadState('uploading');
    if (!clientId) {
      console.error('Client ID not found');
      return;
    }
    const { uploadPromise, cancelUpload } = await uploadFileToS3(file, clientId, (progress) => {
      dispatch(setUploadProgress(progress));
    });

    setCancelUpload(() => cancelUpload);

    try {
      const result = await uploadPromise;
      // if (result.referenceUrl) { //this is already in the state thunk on success request
      //   dispatch(updateTrackReferenceUrl(result.referenceUrl));
      // }
      dispatch(setUploadComplete(true));
      setUploadState('complete');

      if (file.type.startsWith('audio/')) {
        setTimeout(() => {
          if (result.referenceUrl) {
            startSimilarTrackSearch(result.referenceUrl);
          }
          dispatch(resetDialogs());
          setUploadState('select');
        }, 4000);

      } else {
        //from here handle continue button user selects if to use audio track in video
        console.log('Video uploaded successfully, audioReferenceUrl = ', result.referenceUrl);
      }


    } catch (error) {
      console.error('Upload failed:', error);
    }
  }, [clientId, uploadFileToS3, navigate]);


  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file && file.size <= 160 * 1024 * 1024) {
      handleUploadStart(file);
    } else {
      console.log('File type must be mp3, mp4 or mov and size must not exceed 160MB.');
    }
  }, [handleUploadStart]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'audio/mpeg': ['.mp3'],
      'video/mp4': ['.mp4'],
      'video/quicktime': ['.mov'],
    },
    maxSize: 160 * 1024 * 1024,
  });

  const handleCloseClick = () => {
    if (cancelUpload) {
      cancelUpload();
      dispatch(resetUpload())
    }
    setUploadState('select');
    dispatch(resetDialogs());
  };

  //this is only used for video upload - audio goes straight to findSimilarTracks
  //see on upload complete
  const handleContinue = () => {
    if (similaritySelected && trackReferenceUrl) {
      startSimilarTrackSearch(trackReferenceUrl)
    }
    //flag video available
    dispatch(setVideoAvailable(true));
    //reset dialog state
    dispatch(resetDialogs());
    //resey upload state
    setUploadState('select'); //reset upload state not clear
  };

  const startSimilarTrackSearch = (trackReferenceUrl: string) => {
    console.log('Starting similarity search for trackReferenceUrl:', trackReferenceUrl);
    dispatch(setAutoLoad(false));
    dispatch(findSimilarTracks({ url: trackReferenceUrl }));
  }

  const getToggleSwitchText = () => {
    const text = similaritySelected ? 'Use the audio track in the video to find similar tracks' : "Don't use the audio track in the video to find similar tracks";
    return text;
  }


  useEffect(() => {
    if (deeplink && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [deeplink]);

  return (
    <>
      <div className={styles.darken}></div>
      <div className={styles.modalOverlay}>
        <div className={styles.closeButton}>
          <CloseButton onClose={handleCloseClick} />
        </div>
        <div className={styles.container}>
          <UploadHeader mediaType={uploadMediaType} uploadState={uploadState} />
          {uploadState === 'select' && (
            <div {...getRootProps()} >
              <UploadArea getInputProps={getInputProps} inputRef={fileInputRef} />
            </div>
          )}
          {uploadState === 'uploading' && (
            <>
              <UploadProgress progress={progress} onClose={handleCloseClick} file={uploadedFile} />
            </>
          )}
          {uploadState === 'complete' && (
            <>
              {uploadMediaType === 'VIDEO' && (
                <>
                  {trackReferenceUrl && (
                    <div className={styles.switchWrapper}>
                      <CustomSwitch
                        checked={similaritySelected}
                        onToggle={setSimilaritySelected}
                        textStyle={{ fontFamily: 'Juice' }}
                        text={getToggleSwitchText()}
                      />
                    </div>
                  )}
                  <ContinueButton handleContinue={handleContinue} />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const UploadHeader: React.FC<{ mediaType: string, uploadState: UploadStateTypes }> = ({ mediaType, uploadState }) => (
  <>
    {uploadState === 'select' && (
      <>
        <div className={styles.headerTitle}>
          UPLOAD
        </div>
        <div className={styles.headerSubtitle}>
          Upload audio or video to use as reference.
        </div>

      </>
    )}
    {uploadState === 'uploading' && (
      <>
        <div className={`${styles.headerTitle} ${styles.titleEntering}`}>
          UPLOADING {mediaType}
        </div>
        <div className={`${styles.headerSubtitle} ${styles.subtitleEntering}`}>
          {/* Please wait... */}
        </div>
      </>
    )}
    {uploadState === 'complete' && (
      <>
        <div className={`${styles.headerTitle} ${styles.titleEntering}`}>
          UPLOAD COMPLETE
        </div>

        {mediaType === 'AUDIO' && (
          <div className={`${styles.headerSubtitle} ${styles.subtitleEntering}`}>
            Starting similarity search ...
          </div>
        )}

      </>
    )}
  </>
);

export default UploadDialogContainer;