import * as React from 'react';
// import styles from './ContinueButton.module.css';
import StyledButton from '../../../components/shared/StyledButton'; // 


interface NextButtonProps {
  handleClick: () => void
}

const LibraryButton: React.FC<NextButtonProps> = ({ handleClick }) => {

  return (
  
      <StyledButton
        onClick={handleClick}
        bgColor="rgba(var(--main-colors-cyan-light-1-rgb),0.9)"
        hoverBgColor="rgba(var(--main-colors-cyan-light-1-rgb),0.75)"
        textColor="black"
        borderRadius="27px"
        buttonWidth="150px"
        buttonHeight="50px"
      >Go to Library</StyledButton>
  );
};

export default LibraryButton;
