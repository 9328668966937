import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import truncate from 'lodash/truncate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { setSelectedSongById } from 'redux/slices';
import { SongProps } from 'src/types';
import { AppDispatch } from 'redux/store';
import styles from './SongComponent.module.css';
import ArtCoverComponent from './ArtCoverComponent';
import SongDetails from './SongDetails';
import SoundWaveComponent from 'components/media/SoundWaveComponent';
import TimeValues from './TimeValues';
import SimilarityButton from './SimilarityButton';
import FavoriteButton from '../../../components/shared/FavoriteButton';
import DownloadButton from './DownloadButton';
import MoreButton from './MoreButton';
import TagsList from './TagsList';
import downloadAudioService from 'services/downloadAudioService';
import { sanitizeTrackName } from 'utils/helpers';
import { triggerDownload } from 'utils/triggerDownload';
import { manageMediaPlayback } from 'redux/thunks/manageMediaPlaybackThunks';

const SongComponent: React.FC<SongProps> = (props) => {

  // console.log('SongComponent index ', props.index);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [hovered, setHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const selectedSongId = useSelector((state: RootState) => state.songList.selectedSongId);
  const isFavoritesPath = location.pathname === '/search/favorites';
  const isLandingPage = location.pathname === '/home';

  const handleTrackReady = () => {
    //console.log('handleTrackReady ');
    setIsLoading(false)
  };

  const handleDownload = () => {
    // console.log(props);
    if (!props.audioPath) return;
    downloadAudioService({
      song: props,
      operation: 'audio-only',
    }).then(response => {
      triggerDownload(response);
      console.log('Download link:', response);
    })
      .catch(error => {
        console.error('Download failed', error);
      });
  }

  const handleTitleClick = () => {
    dispatch(manageMediaPlayback(selectedSongId, false));
    navigate(`/track/${sanitizeTrackName(props.title)}/${props.id}`);
  }

  useEffect(() => {
    // console.log('SongById, index ',selectedSongId,props.index)
    if (props.index === 0 && selectedSongId === null) {
      // console.log('setting selectedSongId', props.id);
      dispatch(setSelectedSongById(props.id));
    }
  }, [isLoading, props.index, props.id, selectedSongId]);

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => setHovered(isLoading? false : true)} // only change state if track is loaded
      onMouseLeave={() => setHovered(false)}
    >
      <ArtCoverComponent hovered={hovered} id={props.id} isLoading={false} coverSleeveUrl={props.coverSleeveUrl} />
      <div className={styles.songDetails} onClick={handleTitleClick}>
        <SongDetails title={truncate(props.title, { length: 20 })} artist={props.artist} />
      </div>
      {/* <div className={styles.soundWaveContainer}> */}
        <SoundWaveComponent
          id={props.id}
          key={props.id}
          audioUrl={props.audioUrl}
          peaksUrl={props.peaksUrl}
          duration={props.duration}
          onTrackReady={handleTrackReady}
        /> 
      {/* </div> */}

      <div className={styles.timeValues}>
        <TimeValues bpm={props.bpm} duration={props.duration} id={props.id} />
      </div>


      <div className={styles.tagsContainer}>
        {!isFavoritesPath && !isLandingPage &&  <TagsList genres={props.genres} moods={props.moods} instruments={props.instruments} />}
      </div>
   
        {!isFavoritesPath && !isLandingPage && <SimilarityButton song={props} />}
        {!isLandingPage && <FavoriteButton songProps={props} />}
        { !isLandingPage && ( <DownloadButton onDownload={handleDownload} />)}
        {/* Todo: add MoreButton component for sharing and other extra features */}
        {/* <MoreButton song={props} /> */}
      </div>
 
  );
};
export default SongComponent;
// export default React.memo(SongComponent);