import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Chip, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DownloadIcon from '@mui/icons-material/Download';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/redux/store';
import { updateTrackTbl } from '../../../services/trackService';
import PlayBarComponentBasic from '../components/PlayBarComponentBasic';
import styles from './TrackPageContainer.module.css';
import config from '../../../config.js';
import { mapSongs } from '../../../utils/songMapping';
import { SongProps } from 'types/types';
import { getTrackById } from '../../../services/trackService';
import { setSelectedSongById, setGenresFilter, setMoodsFilter, setInstrumentsFilter, resetFilters } from 'redux/slices';
import KeydownManager from '../../../services/KeydownManager'; // Import KeydownManager

interface TrackParams extends Record<string, string | undefined> {
  id: string;
}

//Todo: consolidation Merge with the existing TrackPageContainer 
const TrackPageContainerAdmin: React.FC = () => {

  const { id } = useParams<TrackParams>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const keydownManager = KeydownManager.getInstance();
  const [fetchedSong, setFetchedSong] = useState<any>(null);
  const songState = useSelector((state: RootState) =>
    state.songList.songs.find((song) => song.id === id)
  );


  useEffect(() => {
    if (!songState && id) {
      const fetchSong = async (id: string) => {
        try {
          const trackById = await getTrackById(id);
          if (trackById) {
            const mappedSong = mapSongs([trackById])[0];
            setFetchedSong(mappedSong);
          }
        } catch (error) {
          console.error('Failed to fetch the song:', error);
        }
      };

      fetchSong(id);
    }
  }, [songState, id, dispatch])


  const song:SongProps = fetchedSong || songState;

  //profile image:
  //artists/{artistId}/images/{artistId}.profile.png
  const artistId = song?.artistDetails.artistId
  const backgroundImageUrl = `${config.cdnBaseUrl}/artists/${artistId}/images/${artistId}.profile.png`;
 
  
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editableTitle, setEditableTitle] = useState(song?.title || '');

  const handleTagClick = (tagType: 'genre' | 'mood' | 'instrument', value: string) => {
    const actionMap = {
      genre: setGenresFilter,
      mood: setMoodsFilter,
      instrument: setInstrumentsFilter,
    };

    dispatch(resetFilters());
    dispatch(actionMap[tagType]([value]));

    // Navigate to the search/library page
    navigate('/search/library');
  };

  const handleTitleClick = () => {
    setIsEditingTitle(true);
    keydownManager.disableSpacebar();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditableTitle(e.target.value);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
    handleSaveNewTitle({title: editableTitle});
    keydownManager.enableSpacebar();
  };

  const handleSaveNewTitle = async (attributes: object) => {
    try {
      await updateTrackTbl({
        id: id as string,
       ...attributes
      });
      console.log('Track table update successful!');
    } catch (error) {
      console.error('Update failed:', error);
    }
  }

  useEffect(() => {
    // Cleanup function to run when the component unmounts
    return () => {
      dispatch(setSelectedSongById(null));
    };
  }, [dispatch]);

  if (!song) {
    return <div className={styles.notFound}>Track not found</div>;
  }

  return (
    <div className={styles.trackDetailsContainer}>
      <div className={styles.backgroundImageContainer} style={{ backgroundImage: ` linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)),  url(${backgroundImageUrl})` }}>
      {isEditingTitle ? (
        <input
          type="text"
          value={editableTitle}
          onChange={handleTitleChange}
          onBlur={handleTitleBlur}
          autoFocus
          className={styles.editableTitleInput}
        />
      ) : (
        <h1 onClick={handleTitleClick}>{editableTitle}</h1>
      )}
       
       
       {/* <h1 className={styles.title}>{song.title}</h1> */}
        <h2 className={styles.artist}>Track by {song.artist}</h2>
        <div className={styles.buttons}>
          <IconButton aria-label="play">
            <PlayArrowIcon style={{ color: 'white' }} />
          </IconButton>
          <IconButton aria-label="download">
            <DownloadIcon style={{ color: 'white' }} />
          </IconButton>
          <IconButton aria-label="favorite">
            <FavoriteIcon style={{ color: 'white' }} />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      </div>



      <div className={styles.details}>
        {song.album && (
          <div className={styles.albumContainer}>
            <img src={`${config.cdnBaseUrl}/artists/${artistId}/images/${song.albumDetails?.albumId}.cover.png`} alt={`${song.album} cover`} className={styles.albumImage} />
            <div className={styles.albumTextContainer}>
              <div className={styles.albumTitle}>ALBUM</div>
              <div className={styles.albumName}>{song.album}</div>
            </div>
          </div>
        )}
        <div className={styles.detailItem}>
          <h3>Genre</h3>
          <div className={styles.tags}>
            {song.genres.map((genre, index) => (
              <Chip
                key={index}
                label={genre}
                className={styles.tag}
                onClick={() => handleTagClick('genre', genre)}
              />
            ))}
          </div>
        </div>
        <div className={styles.detailItem}>
          <h3>Mood</h3>
          <div className={styles.tags}>
            {song.moods.map((mood, index) => (
              <Chip
                key={index}
                label={mood}
                className={styles.tag}
                onClick={() => handleTagClick('mood', mood)}
              />
            ))}
          </div>
        </div>
        <div className={styles.detailItem}>
          <h3>Instrument </h3>
          <div className={styles.tags}>
            {song.instruments.map((instrument, index) => (
              <Chip
                key={index}
                label={instrument}
                className={styles.tag}
                onClick={() => handleTagClick('instrument', instrument)}
              />
            ))}
          </div>
        </div>
        <div className={styles.detailItem}>
          <h3>BPM </h3>
          <div className={styles.tags}>
            <Chip label={song.bpm} className={styles.tag} />
          </div>
        </div>
        {/* <div className={styles.detailItem}>
          <h3>Key </h3>
          <div className={styles.tags}>
              <Chip label={song.scale} className={styles.tag} />
          </div>
        </div>

        <div className={styles.detailItem}>
          <h3>Instrumentation </h3>
          <div className={styles.tags}>
           <Chip label={song.instrumentation} className={styles.tag} />
          </div>
        </div> */}
      </div>
      <footer className={styles.footer}>
        <PlayBarComponentBasic song={song} />
      </footer>
    </div>
  );
};

export default TrackPageContainerAdmin;
