import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { fetchMockTracksDetailsByIds, getMockFavorites } from '../../services/favoritesService';
import { SongProps } from 'src/types';

interface FavoritesState {
  favoriteTracksDetails: SongProps[];
}

const FAVORITE_TRACKS_DETAILS = 'favoriteTracksDetailsMonsterMusicIo';

// Local Storage Management Functions
const saveFavoritesToLocalStorage = (favoritesDetails: SongProps[]) => {
  localStorage.setItem(FAVORITE_TRACKS_DETAILS, JSON.stringify(favoritesDetails));
};

const loadFavoritesFromLocalStorage = (): SongProps[] => {
  const favorites = localStorage.getItem(FAVORITE_TRACKS_DETAILS);
  return favorites ? JSON.parse(favorites) : [];
};

const clearFavoritesFromLocalStorage = () => {
  localStorage.removeItem(FAVORITE_TRACKS_DETAILS);
};

const initialState: FavoritesState = {
  favoriteTracksDetails: loadFavoritesFromLocalStorage(),
};

// Thunks
export const initializeFavorites = createAsyncThunk(
  'favorites/initialize',
  async (userEmail: string, { dispatch, getState }) => {
    const storedDetails = loadFavoritesFromLocalStorage();
    if (storedDetails.length === 0) {
      const favoriteTrackIds = await getMockFavorites(userEmail);
      if (favoriteTrackIds.length > 0) {
        const favoritesDetails = await fetchMockTracksDetailsByIds(favoriteTrackIds.map(favorite => favorite.trackId)); 
        dispatch(setFavoriteTracksDetails(favoritesDetails)); // Update state
        saveFavoritesToLocalStorage(favoritesDetails); // Update local storage
      }
    }
  }
);


export const addFavorite = createAsyncThunk(
  'favorites/add',
  async (track: SongProps, { getState, dispatch }) => {
    const state = getState() as RootState;
    const updatedFavorites = [...state.favorites.favoriteTracksDetails, track];
    saveFavoritesToLocalStorage(updatedFavorites); // Update local storage
    dispatch(setFavoriteTracksDetails(updatedFavorites)); // Update state
  }
);


export const removeFavorite = createAsyncThunk(
  'favorites/remove',
  async (trackId: string, { getState, dispatch }) => {
    const state = getState() as RootState;
    const updatedFavorites = state.favorites.favoriteTracksDetails.filter(track => track.id !== trackId);
    saveFavoritesToLocalStorage(updatedFavorites);  
    dispatch(setFavoriteTracksDetails(updatedFavorites));
  }
);

// Slice
const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setFavoriteTracksDetails: (state, action: PayloadAction<SongProps[]>) => {
      state.favoriteTracksDetails = action.payload;
    },
    clearFavorites: (state) => {
      state.favoriteTracksDetails = [];
      clearFavoritesFromLocalStorage();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFavorite.fulfilled, (state, action) => {
        // Handled via thunk, but you could update state here if needed
      })
      .addCase(removeFavorite.fulfilled, (state, action) => {
        // Handled via thunk, but you could update state here if needed
      });
  }
});


// Export actions and selectors
export const { setFavoriteTracksDetails, clearFavorites } = favoritesSlice.actions;

export const selectFavorites = (state: RootState) => state.favorites.favoriteTracksDetails;

export const isTrackFavorited = (state: RootState, trackId: string): boolean => {
  return state.favorites.favoriteTracksDetails.some(track => track.id === trackId);
};
export default favoritesSlice.reducer;
